import React, { useState, useEffect } from 'react';
import '../CSS/GestionVistas.css';
import { Link } from 'react-router-dom';
import logo1 from '../../components/Imagenes/Recurso.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { db } from '../../DB/firebase';
import { collection, query, where, getDocs, orderBy, limit, startAfter, endBefore, doc, updateDoc } from 'firebase/firestore';
import reporte from '../../components/Imagenes/libro-de-visitas.gif';
import visita from '../../components/Imagenes/calendario.gif';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Alert, ListGroup, Modal, Button, Spinner, Table, Pagination, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';



function GestionVisitas() {
    const [showVisitas, setShowVisitas] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null); // Estado para el mensaje de alerta
    const [showDownloadConfirm, setShowDownloadConfirm] = useState(false); // Estado para la confirmación de descarga
    const [reportData, setReportData] = useState([]); // Estado para almacenar los datos del reporte
    const [visitasComentarios, setVisitasComentarios] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [firstVisible, setFirstVisible] = useState(null);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    /* MODAL PARA HISTORIAL DE COMENTARIOS  */
    /* Tabla de historial  */
    const limite = 10;
    const [showHistorial, setShowHistorial] = useState(false);

    const handleShowHistorial = () => setShowHistorial(true);
    const handleCloseHistorial = () => setShowHistorial(false);


    const [comentarios, setComentarios] = useState([]);
    useEffect(() => {
        const fetchComentarios = async () => {
            setLoading(true);
            try {
                const querySnapshot = await getDocs(collection(db, 'visitasComentarios'));
                const comentariosData = querySnapshot.docs.map(doc => {
                    const docData = doc.data();
                    // Convertir el timestamp de fechaHora a un formato legible
                    const fechaHora = docData.fechaHora ? convertTimestampToDate(docData.fechaHora).toLocaleString() : 'Fecha no disponible';
                    return {
                        id: doc.id,
                        ...docData,
                        fechaHora // Añadir la fecha convertida al objeto del comentario
                    };
                });
                // Filtrar comentarios según el estado
                const filteredComentarios = comentariosData.filter(item => item.estado === 1 || item.estado === 2 || item.estado === 3);
                const totalItems = filteredComentarios.length;
                setTotalPages(Math.ceil(totalItems / limite));

                // Mostrar los comentarios de la página actual
                const startIndex = (page - 1) * limite;
                const endIndex = startIndex + limite;
                setComentarios(filteredComentarios.slice(startIndex, endIndex));
            } catch (error) {
                console.error('Error fetching comments:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchComentarios();
    }, [page]);

    const convertTimestampToDate = (timestamp) => {
        if (timestamp && timestamp.seconds) {
            // Convertir el timestamp a una fecha
            return new Date(timestamp.seconds * 1000 + (timestamp.nanoseconds / 1000000));
        }
        return null;
    };




    /* Fechas visitas */
    const handleCloseVisitas = () => {
        setShowVisitas(false);
        setVisitasRange([new Date(), new Date()]); // Reiniciar el rango de fechas
        //setVisitorCount(0); // Reiniciar el conteo de visitantes
    };

    const handleShowVisitas = () => setShowVisitas(true);
    const handleCloseDownloadConfirm = () => setShowDownloadConfirm(false); // Cerrar la confirmación de descarga

    const [showAgenda, setShowAgenda] = useState(false);
    const handleCloseAgenda = () => {
        setShowAgenda(false);
        setAgendaRange([new Date(), new Date()]); // Reiniciar el rango de fechas
    };
    const handleShowAgenda = () => setShowAgenda(true);

    const [visitasRange, setVisitasRange] = useState([new Date(), new Date()]);
    const [agendaRange, setAgendaRange] = useState([new Date(), new Date()]);
    //const [visitorCount, setVisitorCount] = useState(0); // Para almacenar el conteo de visitantes
    const [pageComentario, setPageComentario] = useState(1);
    const [totalPagesComentario, setTotalPagesComentario] = useState(0);
    // Función para obtener los comentarios
    const fetchVisitasComentarios = async (direction = null) => {
        setLoading(true);
        let q;
        if (direction === 'next' && lastVisible) {
            q = query(
                collection(db, 'visitasComentarios'),
                where('estado', '==', 0),
                orderBy('fechaHora'),
                startAfter(lastVisible),
                limit(limite)
            );
        } else if (direction === 'prev' && firstVisible) {
            q = query(
                collection(db, 'visitasComentarios'),
                where('estado', '==', 0),
                orderBy('fechaHora'),
                endBefore(firstVisible),
                limit(limite)
            );
        } else {
            q = query(
                collection(db, 'visitasComentarios'),
                where('estado', '==', 0),
                orderBy('fechaHora'),
                limit(limite)
            );
        }

        try {
            const querySnapshot = await getDocs(q);
            const data = querySnapshot.docs.map(doc => {
                const docData = doc.data();
                let fechaHora;
                if (docData.fechaHora && typeof docData.fechaHora.toDate === 'function') {
                    fechaHora = docData.fechaHora.toDate().toLocaleString();
                } else {
                    fechaHora = 'Fecha no disponible';
                }
                return { id: doc.id, ...docData, fechaHora };
            });

            if (data.length > 0) {
                setFirstVisible(querySnapshot.docs[0]);
                setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
                setVisitasComentarios(prevVisitasComentarios => {
                    if (direction === 'next') {
                        return [...prevVisitasComentarios, ...data];
                    } else if (direction === 'prev') {
                        return [...data, ...prevVisitasComentarios];
                    } else {
                        return data;
                    }
                });
                const totalItemsComentario = await getTotalItems();
                setTotalPagesComentario(Math.ceil(totalItemsComentario / limite));
            }
        } catch (error) {
            console.error('Error obteniendo comentarios: ', error);
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchVisitasComentarios();
    }, []);
    const getTotalItems = async () => {
        const q = query(
            collection(db, 'visitasComentarios'),
            where('estado', '==', 0)
        );
        const querySnapshot = await getDocs(q);
        return querySnapshot.size;
    };

    const handleNextPageComentario = () => {
        fetchVisitasComentarios('next');
        setPageComentario(prevPage => prevPage + 1);
    };

    const handlePreviousPageComentario = () => {
        fetchVisitasComentarios('prev');
        setPageComentario(prevPage => prevPage - 1);
    };

    // Función para actualizar el estado del comentario
    const handleAccept = async (id) => {
        try {
            const comentarioRef = doc(db, 'visitasComentarios', id);
            await updateDoc(comentarioRef, { estado: 1 });
            console.log('Comentario aceptado');

            setVisitasComentarios(prevVisitasComentarios =>
                prevVisitasComentarios.map(comentario =>
                    comentario.id === id ? { ...comentario, estado: 1 } : comentario
                ).filter(comentario => comentario.estado === 0)
            );
        } catch (error) {
            console.error('Error actualizando el estado del comentario: ', error);
        }
    };

    const handleDecline = async (id) => {
        try {
            const comentarioRef = doc(db, 'visitasComentarios', id);
            await updateDoc(comentarioRef, { estado: 2 });
            console.log('Comentario declinado');

            setVisitasComentarios(prevVisitasComentarios =>
                prevVisitasComentarios.map(comentario =>
                    comentario.id === id ? { ...comentario, estado: 2 } : comentario
                ).filter(comentario => comentario.estado === 0)
            );
        } catch (error) {
            console.error('Error actualizando el estado del comentario: ', error);
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(prevPage => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(prevPage => prevPage - 1);
        }
    };


    const handleGenerateReport = async () => {
        if (visitasRange[0] && visitasRange[1]) {
            const visitasRef = collection(db, 'visitasCookies');
            const q = query(
                visitasRef,
                where('timestamp', '>=', visitasRange[0]),
                where('timestamp', '<=', visitasRange[1])
            );
    
            try {
                const querySnapshot = await getDocs(q);
                const visitasData = querySnapshot.docs.map(doc => {
                    const docData = doc.data();
                    const timestamp = docData.timestamp ? convertTimestampToDate(docData.timestamp).toISOString().split('T')[0] : 'Fecha no disponible'; // Solo la fecha
                    return { id: doc.id, ...docData, timestamp };
                });
    
                // Set data for the chart
                setReportData(visitasData);
                setShowDownloadConfirm(true);
            } catch (error) {
                console.error('Error generando el reporte: ', error);
            }
        }
    };

    const handleDownloadReport = async () => {
        if (!reportData || reportData.length === 0) {
            console.error('No hay datos para generar el reporte.');
            return;
        }
    
        // Procesar los datos
        const visitorsByDay = reportData.reduce((acc, { timestamp }) => {
            const day = timestamp;  // timestamp ya es solo la fecha en formato YYYY-MM-DD
            if (!acc[day]) {
                acc[day] = 0;
            }
            acc[day] += 1;
            return acc;
        }, {});
    
        const processedData = Object.entries(visitorsByDay).map(([day, visitors]) => ({
            day,
            visitors
        }));
    
        // Calcular el total de visitantes
        const totalVisitors = processedData.reduce((sum, { visitors }) => sum + visitors, 0);
    
        // Agregar el total de visitantes a los datos procesados
        processedData.push({
            day: 'Total',
            visitors: totalVisitors
        });
    
        // Crear el archivo Excel
        const worksheet = XLSX.utils.json_to_sheet(processedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Visitas');
    
        // Obtener la fecha y hora actual para el nombre del archivo
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        const timestamp = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
    
        // Guardar el archivo
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const file = new Blob([excelBuffer], { type: EXCEL_TYPE });
        saveAs(file, `reporte_visitas_${timestamp}.xlsx`);
    
        setShowDownloadConfirm(false);
    };
    
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    
   

    /* GENERAR REPORTE DE AGENDAS PARA RECORRIDO  */

    const [showAgendaModal, setShowAgendaModal] = useState(false);
    const [showDownloadConfirmAgenda, setShowDownloadConfirmAgenda] = useState(false);
    const [reportDataAgenda, setReportDataAgenda] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const handleShowAgendaModal = () => setShowAgendaModal(true);
    const handleCloseAgendaModal = () => {
        setShowAgendaModal(false);
        setErrorMessage(''); // Clear error message on close
    };
    const handleShowDownloadConfirmAgenda = () => setShowDownloadConfirmAgenda(true);
    const handleCloseDownloadConfirmAgenda = () => setShowDownloadConfirmAgenda(false);


    const fetchAgendaData = async (startDate, endDate) => {
        // Convert startDate and endDate to string for comparison
        const startStr = startDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
        const endStr = endDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD

        const q = query(
            collection(db, "agenda"),
            where("fechaYHora", ">=", startStr),
            where("fechaYHora", "<=", endStr)
        );
        const querySnapshot = await getDocs(q);
        const data = [];
        querySnapshot.forEach((doc) => {
            const docData = doc.data();
            data.push({ ...docData });
        });
        return data;
    };

    const handleGenerateReportAgenda = async () => {
        const [startDate, endDate] = agendaRange;
        if (!startDate || !endDate) {
            showError("Por favor seleccione un rango de fechas.");
            return;
        }

        const data = await fetchAgendaData(startDate, endDate);
        if (data.length === 0) {
            showError('No hay datos para generar el reporte.');
            return;
        }

        setReportDataAgenda(data);
        handleShowDownloadConfirmAgenda();
        handleCloseAgendaModal();
    };

    const showError = (message) => {
        setErrorMessage(message);
        setTimeout(() => {
            setErrorMessage('');
        }, 2000); // 
    };
    const handleDownloadReportAgenda = () => {
        if (!reportDataAgenda || reportDataAgenda.length === 0) {
            console.error('No hay datos para generar el reporte.');
            return;
        }

        // Procesar los datos
        const processedDataAgenda = reportDataAgenda.map(item => ({
            "Fecha y Hora": item.fechaYHora,
            "Mujer": item.generoF,
            "Hombre": item.generoM,
            "Otro": item.generoO,
            "Museo": item.museo,
            "Nombre": item.nombre,
            "Número de Personas": item.numPersonas
        }));

        console.log("Datos procesados para el Excel: ", processedDataAgenda);

        // Crear el archivo Excel
        const worksheet = XLSX.utils.json_to_sheet(processedDataAgenda);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Agendas');

        // Obtener la fecha y hora actual para el nombre del archivo
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        const timestamp = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;

        // Guardar el archivo
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const file = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        saveAs(file, `reporte_agendas_${timestamp}.xlsx`);

        handleCloseDownloadConfirmAgenda();

       
    };
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" className='custom-tooltip'{...props} >
          Elija una fecha de inicio y una fecha de final
        </Tooltip>
      );
    return (
        <div>
            <nav className="navbar">
                <div className="container-fluid">
                    <button className="navbar-brand btn btn2">
                        <Link to="/InicioAdmin"><img src={logo1} alt="Bootstrap" width="40" height="35" /></Link>
                    </button>
                    <div className='d-flex'>
                        <Link to="/InicioAdmin" className='letra' style={{ textDecoration: 'none' }}>
                            <button className="btn btn-agenda" type='button'>
                                Inicio
                            </button>
                        </Link>
                    </div>
                </div>
            </nav>
            <body>
                <div className='container-fluid'>
                    <h1 className='bienvenido'>Gestión de Visitas</h1>
                    <div className="row ">
                        <div className="col-md-6 mb">
                            <div className="card">
                                <div className="card-body text-center">
                                    <h5 className="card-title mtitulo">Fechas visitas</h5>
                                    <div>
                                        <img src={visita} alt="Días" className="img-fluid" style={{ width: '300px', height: '300px', objectFit: 'fill' }} />
                                    </div>

                                    <button className="btn btn-warning mt-3 btnVC" onClick={handleShowVisitas}>Generar reporte</button>
                                    <Modal show={showVisitas} onHide={handleCloseVisitas}>
                                        <Modal.Header closeButton className='n'>
                                            <Modal.Title>Generar Reporte de Visitas</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {alertMessage && (
                                                <Alert variant='alertVariant' onClose={() => setAlertMessage(null)} dismissible>
                                                    {alertMessage}
                                                </Alert>
                                            )}
                                            Seleccione las Fechas <OverlayTrigger
                                            
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltip}
                                                
                                            >
                                                <span className="ml-2">
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </span>
                                            </OverlayTrigger>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                                <DatePicker
                                                    selected={visitasRange[0]}
                                                    onChange={(dates) => setVisitasRange(dates)}
                                                    startDate={visitasRange[0]}
                                                    endDate={visitasRange[1]}
                                                    selectsRange
                                                    inline
                                                    className="datepicker"
                                                />
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button className='color2' variant='warning' onClick={handleCloseVisitas}>
                                                Cerrar
                                            </Button>
                                            <Button className='btnVC' variant='warning' onClick={handleGenerateReport}>
                                                Generar Reporte
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb">
                            <div className="card" >
                                <div className="card-body text-center">
                                    <h5 className="card-title">Agendas recorrido</h5>
                                    <div>
                                        <img src={reporte} alt="Agendas" className='img-fluid' style={{ width: '300px', height: '300px', objectFit: 'fill' }} />
                                    </div>
                                    <button className="btn btn-warning mt-3 btnVC" onClick={handleShowAgendaModal}>Generar reporte</button>
                                    <Modal show={showAgendaModal} onHide={handleCloseAgendaModal}>
                                        <Modal.Header closeButton className='n'>
                                            <Modal.Title>Generar reporte de Agendas</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>Seleccione las Fechas <OverlayTrigger
                                            
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip}
                                            
                                        >
                                            <span className="ml-2">
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                            </span>
                                        </OverlayTrigger>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                                <DatePicker
                                                    selected={agendaRange[0]}
                                                    onChange={(dates) => setAgendaRange(dates)}
                                                    startDate={agendaRange[0]}
                                                    endDate={agendaRange[1]}
                                                    selectsRange
                                                    inline
                                                    className="datepicker"
                                                />
                                            </div>
                                            {errorMessage && <Alert variant="danger" className="mt-2">{errorMessage}</Alert>}
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button className='color2' variant='warning' onClick={handleCloseAgendaModal}>
                                                Cancelar
                                            </Button>
                                            <Button className='btnVC' variant='warning' onClick={handleGenerateReportAgenda}>
                                                Generar Reporte
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                                <Modal show={showDownloadConfirmAgenda} onHide={handleCloseDownloadConfirmAgenda}>
                                    <Modal.Header closeButton className='n'>
                                        <Modal.Title>Descargar Reporte</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {errorMessage && <Alert variant="danger" className="mt-2">{errorMessage}</Alert>}
                                        <p>¿Desea descargar el reporte?</p>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="danger" className='color2' onClick={handleCloseDownloadConfirmAgenda}>
                                            No
                                        </Button>
                                        <Button variant="warning" className='btnVC' onClick={handleDownloadReportAgenda}>
                                            Sí
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </div>
                    <hr className='linea' />
                    <h1 className='bienvenido'>Gestión de Comentarios</h1>

                    <div className='container-lg'>
                        <div className='d-flex justify-content-between align-items-center mb-3'>
                            <div></div>
                            <Button onClick={handleShowHistorial} className='btnVC'> Historial </Button>
                        </div>
                        <ListGroup className='container-lg'>
                            {visitasComentarios.map((item) => (
                                <ListGroup.Item key={item.id} className="d-flex align-items-start">
                                    <div className="flex-column col-comment">
                                        <div className="font-weight-bold person">{item.namePersona}</div>
                                        <div>{item.comentario}</div>
                                    </div>
                                    <div className="mx-3 col-date">{item.fechaHora}</div>
                                    <div className='col-actions'>
                                        <Button
                                            variant="primary"
                                            className="mx-1 btnVC"
                                            onClick={() => handleAccept(item.id)}
                                            disabled={item.estado === 1 || item.estado === 2} // Deshabilitar si el estado es 1 o 2
                                        >
                                            Aceptar
                                        </Button>
                                        <Button
                                            variant="danger"
                                            className="mx-1 color2"
                                            onClick={() => handleDecline(item.id)}
                                            disabled={item.estado === 1 || item.estado === 2} // Deshabilitar si el estado es 1 o 2
                                        >
                                            Declinar
                                        </Button>
                                    </div>
                                </ListGroup.Item>
                            ))}
                            {loading ? (
                                <Spinner animation="border" variant="warning" />
                            ) : (
                                <footer>
                                    <Pagination>
                                        <Pagination.Prev onClick={handlePreviousPageComentario} disabled={pageComentario === 1} />
                                        <Pagination.Item>{pageComentario}</Pagination.Item>
                                        <Pagination.Next onClick={handleNextPageComentario} disabled={pageComentario === totalPagesComentario} />
                                    </Pagination>
                                </footer>
                            )}
                        </ListGroup>
                        <div className='modal-dialog modal-lg mod'>
                            <Modal show={showHistorial} onHide={handleCloseHistorial} className='mod custom-modal' dialogClassName="custom-dialog">
                                <Modal.Header closeButton className='n'>
                                    <Modal.Title>Historial comentarios</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='table-responsive-lg'>
                                        <Table className='table-custom caption-top table-xl ' striped>
                                            <thead className='table-light'>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th>Comentario</th>
                                                    <th>Fecha</th>
                                                    <th>Estado</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {comentarios.map((item) => {
                                                    let fechaHora;
                                                    if (item.fechaHora && typeof item.fechaHora.toDate === 'function') {
                                                        fechaHora = item.fechaHora.toDate();
                                                    } else {
                                                        fechaHora = new Date(item.fechaHora);
                                                    }
                                                    return (
                                                        <tr key={item.id}>
                                                            <td>{item.namePersona}</td>
                                                            <td>{item.comentario}</td>
                                                            <td>{item.fechaHora.toLocaleString()}</td>
                                                            <td>
                                                                {item.estado === 1 ? 'Aceptado' :
                                                                    item.estado === 2 ? 'Declinado' :
                                                                        item.estado === 3 ? 'Ya publicado' :
                                                                            'Estado desconocido'}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan="4">
                                                        <Pagination className='pag'>
                                                            <Pagination.Prev onClick={handlePreviousPage} disabled={page === 1} />
                                                            <Pagination.Item>{page}</Pagination.Item>
                                                            <Pagination.Next onClick={handleNextPage} disabled={page === totalPages} />
                                                        </Pagination>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </div>
                                </Modal.Body>
                            </Modal>

                        </div>

                    </div>
                </div>
            </body>
            <br></br>
            <Modal show={showDownloadConfirm} onHide={handleCloseDownloadConfirm}>
                <Modal.Header closeButton className='n'>
                    <Modal.Title>Descargar Reporte</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Desea descargar el reporte</p>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" className='color2' onClick={handleCloseDownloadConfirm}>
                        No
                    </Button>
                    <Button variant="warning" className='btnVC' onClick={handleDownloadReport}>
                        Sí
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default GestionVisitas;
