import React, { useContext, useEffect, useState } from 'react';
import { auth } from '../DB/firebase';
import { setPersistence, browserSessionPersistence } from 'firebase/auth';

const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const setAuthPersistence = async () => {
      try {
        await setPersistence(auth, browserSessionPersistence);
        const unsubscribe = auth.onAuthStateChanged(user => {
          setCurrentUser(user);
          setLoading(false); 
        });
        return unsubscribe;
      } catch (error) {
        console.error("Error setting persistence:", error);
        setLoading(false);
      }
    };

    const unsubscribe = setAuthPersistence();

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  const value = {
    currentUser
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children} 
    </AuthContext.Provider>
  );
};
