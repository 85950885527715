import React, { useEffect, useState } from 'react';
import '../CSS/Antes.css';
import logo from '../../components/Imagenes/Recurso.png';
import { Link } from 'react-router-dom';
import { db } from '../../DB/firebase';
import { collection, getDocs } from "firebase/firestore";

function Tiempo() {
    const [approvedItems, setApprovedItems] = useState([]);

    useEffect(() => {
        const fetchApprovedItems = async () => {
            const querySnapshot = await getDocs(collection(db, "antesDespues"));
            const itemsData = querySnapshot.docs
                .map(doc => ({ id: doc.id, ...doc.data() }))
                .filter(item => item.approved)
                .sort((a, b) => a.id.localeCompare(b.id)); // Ordenar por id
            setApprovedItems(itemsData);
        };

        fetchApprovedItems();
    }, []);

    const antesItems = approvedItems.filter(item => item.category === 'antes');
    const despuesItems = approvedItems.filter(item => item.category === 'despues');
    const maxItems = Math.max(antesItems.length, despuesItems.length);

    return (
        <div>
            <nav className="navbar">
                <div className="container-fluid">
                    <button className="navbar-brand btn btn2">
                        <Link to="/"><img src={logo} alt="Bootstrap" width="30" height="24" /></Link>
                    </button>
                    <div className='d-flex'>
                        <Link to="/" className='letra' style={{ textDecoration: 'none' }}>
                            <button className="btn btn-agenda letra">
                                Inicio
                            </button>
                        </Link>
                    </div>
                </div>
            </nav>
            <div>
                <h1 className="AntesT">Antes y Después</h1>
                <p className="Subtitulo">Explora la evolución de Tulancingo a través del tiempo</p>
                <div className="image-container">
                    {Array.from({ length: maxItems }).map((_, index) => (
                        <div className="image-row" key={index}>
                            <div className="column antes-column">
                                {antesItems[index] && (
                                    <div className="image-with-text">
                                        <img src={antesItems[index].image} className="image" alt="Antes" width="300" height="200" />
                                        <h5>{antesItems[index].title}</h5>
                                        <p className="image-text">{antesItems[index].description}</p>
                                    </div>
                                )}
                            </div>
                            <div className="column cross-column">
                                {antesItems[index] && despuesItems[index] && (
                                    <div className="cross">
                                        <div className="horizontal"></div>
                                        <div className="vertical"></div>
                                    </div>
                                )}
                            </div>
                            <div className="column despues-column">
                                {despuesItems[index] && (
                                    <div className="image-with-text">
                                        <img src={despuesItems[index].image} className="image" alt="Después" width="300" height="200"/>
                                        <h5>{despuesItems[index].title}</h5>
                                        <p className="image-text">{despuesItems[index].description}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Tiempo;
