import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../../DB/firebase';
import '../CSS/agenda.css';
import { Link } from 'react-router-dom';
import logo from '../../components/Imagenes/Recurso.png';
import agendaGif from '../../components/Imagenes/agenda.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

export default function AgendaRecorrido() {
  const [events, setEvents] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [showDateModal, setShowDateModal] = useState(false);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [formData, setFormData] = useState({
    museo: '',
    nombre: '',
    email: '',
    numPersonas: '',
    generoF: '',
    generoM: '',
    generoO: ''
  });
  const [reservedTimes, setReservedTimes] = useState({});
  const [searchTerm, setSearchTerm] = useState(''); // Estado para el término de búsqueda

  useEffect(() => {
    const fetchEvents = async () => {
      const querySnapshot = await getDocs(collection(db, "eventos"));
      const eventsList = querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(event => event.approved)
        .map(event => ({
          ...event,
          dateObject: new Date(event.date)
        }))
        .sort((a, b) => a.dateObject - b.dateObject);

      setEvents(eventsList);
    };

    const fetchReservations = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "agenda"));
        const reservations = querySnapshot.docs
          .map(doc => doc.data())
          .reduce((acc, reservation) => {
            if (reservation.fechaYHora) {
              const [date, time] = reservation.fechaYHora.split(' ');
              if (date && time) {
                if (!acc[date]) acc[date] = [];
                acc[date].push(time);
              }
            }
            return acc;
          }, {});

        setReservedTimes(reservations);
      } catch (error) {
        console.error("Error fetching reservations: ", error);
      }
    };


    fetchEvents();
    fetchReservations();
  }, []);

  const handleShow = (event) => {
    setSelectedEvent(event);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const handleOpenDateModal = () => setShowDateModal(true);
  const handleCloseDateModal = () => setShowDateModal(false);
  const handleCloseTimeModal = () => setShowTimeModal(false);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setShowDateModal(false);
    setShowTimeModal(true);
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
    setShowTimeModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "agenda"), {
        ...formData,
        fechaYHora: `${selectedDate} ${selectedTime}`
      });
      alert('Recorrido agendado con éxito');
      // Limpiar el formulario
      setFormData({
        museo: '',
        nombre: '',
        email: '',
        numPersonas: '',
        generoF: '',
        generoM: '',
        generoO: ''
      });
      setSelectedDate('');
      setSelectedTime('');
      // Actualizar la lista de reservas
      const updatedReservations = { ...reservedTimes };
      if (!updatedReservations[selectedDate]) updatedReservations[selectedDate] = [];
      updatedReservations[selectedDate].push(selectedTime);
      setReservedTimes(updatedReservations);
    } catch (error) {
      console.error("Error adding document: ", error);
      alert('Error al agendar recorrido');
    }
  };

  const getFutureDates = () => {
    const dates = [];
    const today = new Date();
    for (let i = 1; i <= 30; i++) {
      const date = new Date();
      date.setDate(today.getDate() + i);
      const day = date.getDay();
      if (day !== 0 && day !== 1) { // Excluir domingos y lunes
        const dateString = date.toISOString().split('T')[0];
        dates.push({
          date: dateString,
          available: true,
          times: getAvailableTimes(dateString)
        });
      }
    }
    return dates;
  };

  const getAvailableTimes = (date) => {
    const times = getDefaultTimes(date);
    if (reservedTimes[date]) {
      return times.filter(time => !reservedTimes[date].includes(time));
    }
    return times;
  };

  const getDefaultTimes = (date) => {
    const day = new Date(date).getDay();
    if (day === 0) { // Domingo
      return ['10:00', '12:00', '14:00', '16:00'];
    } else { // Martes a Sábado
      return ['09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00'];
    }
  };

  const availableDates = getFutureDates();
  const selectedDateDetails = availableDates.find(d => d.date === selectedDate);

  const filteredEvents = events.filter(event => {
    const searchWords = searchTerm.toLowerCase().split(' ');
    return searchWords.every(word =>
      event.title.toLowerCase().includes(word) ||
      event.shortDescription.toLowerCase().includes(word) ||
      event.longDescription.toLowerCase().includes(word)
    );
  });

  return (
    <div>
      <nav className="navbar">
        <div className="container-fluid">
          <button className="navbar-brand btn btn2">
            <Link to="/"><img src={logo} alt="Bootstrap" width="30" height="24" /></Link>
          </button>
          <div className='d-flex'>
            <Link to="/" className='letra' style={{ textDecoration: 'none' }}>
              <button className="btn btn-agenda letra">
                Inicio
              </button>
            </Link>
          </div>
        </div>
      </nav>
      <section className='body'>
        <div className="row justify-content-between">
          <div className="col-4">
            <h1 className="tituloa">Eventos</h1>
          </div>
          <div className="col-4 align-items-center">
            <form className="d-flex flex-row mb-3 align-items-center" role="search" onSubmit={(e) => e.preventDefault()}>
              <div className="flex-grow-1 me-2">
                <input
                  className="form-control"
                  type="search"
                  id='search'
                  placeholder="Buscar"
                  aria-label="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} // Manejar cambios en el término de búsqueda
                />
              </div>
              <div>
                <button className="btn btn-primary search" type="submit" style={{ backgroundColor: '#ffcc66', border: 'none' }}>
                  <FontAwesomeIcon icon={faMagnifyingGlass} style={{ fontSize: '1.25rem' }} />
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="App" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          {filteredEvents.length > 0 ? (
            filteredEvents.map(event => (
              <div className="event-card" key={event.id}>
                <img src={event.image} alt={event.title} className="event-image" />
                <div className="event-content">
                  <h2 className="event-title">{event.title}</h2>
                  <p className="event-description">{event.shortDescription}</p>
                  <p className="event-date">{event.date}</p>
                  <button className="event-button" onClick={() => handleShow(event)}>
                    Ver más
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="no-events-message">Evento no encontrado</p>
          )}

          {show && selectedEvent && (
            <div className="modal-overlay" onClick={handleClose}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                  <h2>{selectedEvent.title}</h2>
                  <button className="modal-close-button" onClick={handleClose}>
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <p style={{ textAlign: "justify", fontWeight: 'lighter' }}>{selectedEvent.longDescription}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <hr className='linea ' />
      <section className='body'>
        <br />
        <h1 className="tituloa">Agenda tu recorrido en el museo</h1>
        <div className="row justify-content-center App2 ">
          <div className="form-container col-md-6">
            <form className="agenda-form" onSubmit={handleSubmit}>
              <label className='form-label '>
                Museo
                <select
                  name="museo"
                  className='form-select selec'
                  value={formData.museo}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" disabled>Seleccione el museo</option>
                  <option value="Museo del Ferrocarril">Museo del Ferrocarril</option>
                  <option value="Museo del Santo">Museo del Santo</option>
                  <option value="Museo de Datos Historicos">Museo de Datos Historicos</option>
                </select>
              </label>
              <label className='form-label'>
                Fecha y Hora
                <div className="date-input-container row">
                  <div className="input-group">
                    <input
                      type="text"
                      className='form-control'
                      id='fechayhora'
                      placeholder="Selecciona fecha y hora"
                      value={`${selectedDate} ${selectedTime}`}
                      readOnly
                      onClick={handleOpenDateModal}
                      required
                    />
                    <div className="input-group-append">
                      <button type="button" onClick={handleOpenDateModal} className="calendar-button">
                        <FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: '1.5rem' }} />
                      </button>
                    </div>
                  </div>
                </div>
              </label>
              <br></br>
              <label className='form-label '>
                Nombre completo
                <input
                  type="text"
                  name="nombre"
                  placeholder="Ingrese nombre"
                  className='form-control '
                  value={formData.nombre}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label className='form-label'>
                Correo electrónico
                <input
                  type="email"
                  name="email"
                  placeholder="correo@gmail.com"
                  className='form-control'
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <div className='row'>
                <label className='form-label'>
                  Número de personas
                  <input
                    type="number"
                    name="numPersonas"
                    placeholder="Ingrese número de personas"
                    className='form-control'
                    value={formData.numPersonas}
                    onChange={handleInputChange}
                    required
                  />
                </label>
                <label className='form-label'>
                  Género
                  <div className="gender-options row">
                    <div className='col-md-4'>
                      F: <input
                        type="number"
                        name="generoF"
                        className='form-control'
                        value={formData.generoF}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className='col-md-4'>
                      M: <input
                        type="number"
                        name="generoM"
                        className='form-control'
                        value={formData.generoM}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className='col-md-4'>
                      Otros: <input
                        type="number"
                        name="generoO"
                        className='form-control'
                        value={formData.generoO}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </label>
                <div className="form-text">Ingrese la cantidad de personas por su género</div>
              </div>
              <div className="row justify-content-center">
                <button type="submit" className="boton_agen" style={{ backgroundColor: '#ffdf1b' }}>Agenda recorrido</button>
              </div>
            </form>
          </div>
          <div className='col-md-6 .offset-md-6'>
            <img src={agendaGif} alt="Agenda" className="agenda-image" />
          </div>
        </div>
      </section>

      {/* Modal de selección de fecha */}
      {showDateModal && (
        <div className="modal-overlay" onClick={handleCloseDateModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h2>Selecciona una fecha</h2>
              <button className="modal-close-button" onClick={handleCloseDateModal}>
                &times;
              </button>
            </div>
            <div className="modal-body date-grid">
              {availableDates.map(date => (
                <div key={date.date} className="date-item">
                  <button
                    className={`date-button ${date.available ? 'available' : 'unavailable'}`}
                    onClick={() => date.available && handleDateSelect(date.date)}
                  >
                    {date.date}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Modal de selección de hora */}
      {showTimeModal && selectedDateDetails && (
        <div className="modal-overlay" onClick={handleCloseTimeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h2>Selecciona una hora</h2>
              <button className="modal-close-button" onClick={handleCloseTimeModal}>
                &times;
              </button>
            </div>
            <div className="modal-body time-grid">
              {selectedDateDetails.times.map(time => (
                <div key={time} className="time-item">
                  <button
                    className="time-button"
                    onClick={() => handleTimeSelect(time)}
                  >
                    {time}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
