import React, { useState, useEffect } from "react";
import './App.css';
import Carousel, { CarouselItem } from "./components/ui/Carousel";
import logo from "./components/Imagenes/Recurso.png";
import { Link, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from './DB/firebase';
import { db } from './DB/firebase';
import Cookies from 'js-cookie';
import { collection, addDoc, query, orderBy, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import tren from './components/Imagenes/tren.png'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Carousel as BootstrapCarousel } from 'react-bootstrap';


// Importa imágenes para el carrusel
import img1 from './components/Imagenes/panoramica3.jpg';
import img2 from './components/Imagenes/panoramica2.jpg';
import img3 from './components/Imagenes/panoramica.jpg';

// Importa imágenes para botones
import vrImage from './components/Imagenes/vr.jpg';
import agendaImage from './components/Imagenes/agenda.jpg';
import beforeAfterImage from './components/Imagenes/antes-despues.jpg';
import landmarksImage from './components/Imagenes/lugares-emblematicos.jpg';

// Importa la imagen de Quetzalcóatl
import quetzalcoatlImage from './components/Imagenes/quetzalcoatl.jpg';

// Importa la imagen de Huapalcalco
import huapalcalcoImage from './components/Imagenes/huapalcalco.jpg';

// Importa la imagen del código QR
import qrImage from './components/Imagenes/movil3.png';
import qrImage1 from './components/Imagenes/qr-code.png';


// Datos para el carrusel
const carouselImages = [
  { src: img1, alt: 'Imagen 1' },
  { src: img2, alt: 'Imagen 2' },
  { src: img3, alt: 'Imagen 3' },
  // Agrega más imágenes según sea necesario
];

// Datos "¿Sabías que...?"
const sabiasQueData = [
  {
    text: `Quetzalcóatl vivió en Tulancingo.\n\nAl cumplir veintiocho años Ce Acatl Topiltzin marchó de Cuextlán hasta un fértil y benevolente valle al que llamó Tollantzinco. Las crónicas Toltecas señalan que Quetzalcóatl se estableció en Tollantzinco durante cuatro años y ahí edificó su huapalcalli, previo a partir a lo que hoy es Tula y que fuera la capital de los Toltecas.`,
    image: quetzalcoatlImage
  },
  {
    text: `Es uno de los asentamientos humanos más antiguos de México.\n\nEn Huapalcalco se han localizado vestigios con más de 14 mil años de antigüedad. De igual manera, en las inmediaciones de los cerros del Huiztle y la meseta de Huapalcalco, fueron halladas pinturas rupestres que transmiten el testimonio de los grupos primitivos que ahí habitaron, en representaciones de seres humanos, trazos esquemáticos y figuras astronómicas que datan aproximadamente del año 7000 a.C.`,
    image: huapalcalcoImage
  },
  // Añade más datos aquí
];

// Componente funcional App
const App = () => {

  const [comentariosUsuarios, setComentariosUsuarios] = useState([]);
  const [showTermsModal, setShowTermsModal] = useState(false);

  // Función para obtener los comentarios con estado 1
  const fetchComentariosUsuarios = async () => {
    try {
      const q = query(
        collection(db, 'visitasComentarios'),
        where('estado', '==', 1), // Filtra por estado 1
        orderBy('fechaHora')
      );
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map(doc => {
        const docData = doc.data();
        let fechaHora;
        if (docData.fechaHora && typeof docData.fechaHora.toDate === 'function') {
          fechaHora = docData.fechaHora.toDate().toLocaleDateString(); // Formatear fecha
        } else {
          fechaHora = 'Fecha no disponible';
        }
        return { id: doc.id, ...docData, fechaHora };
      });
      setComentariosUsuarios(data);
    } catch (error) {
      console.error('Error obteniendo comentarios: ', error);
    }
  };

  // Función para actualizar el estado del comentario en Firestore
  const updateComentarioEstado = async (comentarioId) => {
    try {
      const comentarioDocRef = doc(db, 'visitasComentarios', comentarioId);
      await updateDoc(comentarioDocRef, {
        estado: 3
      });
    } catch (error) {
      console.error('Error actualizando el estado del comentario: ', error);
    }
  };

  useEffect(() => {
    fetchComentariosUsuarios();
  }, []);

  useEffect(() => {
    console.log('Comentarios Usuarios:', comentariosUsuarios);
  }, [comentariosUsuarios]);

  // Verificar si hay más de 4 comentarios y actualizar el estado del primero si es necesario
  useEffect(() => {
    if (comentariosUsuarios.length > 4) {
      const comentarioId = comentariosUsuarios[0].id;
      setComentariosUsuarios(prevComentarios => prevComentarios.slice(1)); // Eliminar el primer comentario inmediatamente
      updateComentarioEstado(comentarioId); // Actualizar el estado del comentario en Firestore
    }
  }, [comentariosUsuarios]);

  const [currentSabiasQueIndex, setCurrentSabiasQueIndex] = useState(0);

  const handleSabiasQueChange = (index) => {
    setCurrentSabiasQueIndex(index);
  };

  const [show, setShow] = useState(false);
  const [correo, setCorreo] = useState('');
  const [contraseña, setContraseña] = useState('');
  const [error, setError] = useState('');
  const [showRecuperar, setShowRecuperar] = useState(false);
  const [emailRecuperacion, setEmailRecuperacion] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseRecuperar = () => setShowRecuperar(false);
  const handleShowRecuperar = () => setShowRecuperar(true);

  const handleLogin = async () => {
    if (correo === '' || contraseña === '') {
      setError('Por favor, complete todos los campos.');
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, correo, contraseña);
      navigate('/InicioAdmin');
    } catch (error) {
      setError('Error al iniciar sesión. Verifique su correo y contraseña.');
    }
  };

  const handleRecuperarContraseña = async () => {
    try {
      await sendPasswordResetEmail(auth, emailRecuperacion);
      setEmailRecuperacion('');
      handleCloseRecuperar();
      alert('Correo de recuperación enviado. Verifique su bandeja de entrada.');
    } catch (error) {
      setError('Error al enviar correo de recuperación. Verifique su correo.');
    }
  };

  useEffect(() => {
    const visitCookie = Cookies.get('visitCookie');

    if (!visitCookie) {
      const uniqueID = `visit_${new Date().getTime()}`;

      // Establece la cookie para evitar duplicados
      Cookies.set('visitCookie', uniqueID, { expires: 1 });

      // Guarda el ID y el timestamp en Firestore
      const visitsCollection = collection(db, 'visitasCookies');

      addDoc(visitsCollection, {
        id: uniqueID,
        timestamp: new Date(),
      })
        .then(() => {
          console.log('Documento añadido correctamente');
        })
        .catch((error) => {
          console.error('Error añadiendo documento: ', error);
        });

      setShowTermsModal(true);
    }

    const preventBackNavigation = () => {
      window.history.pushState(null, '', window.location.href);
      window.addEventListener('popstate', () => {
        window.history.pushState(null, '', window.location.href);
      });
    };

    preventBackNavigation();
  }, []);

  const handleAcceptTerms = async () => {
    try {
      const uniqueID = Cookies.get('visitCookie');
      const termsCollection = collection(db, 'terminos');
      await addDoc(termsCollection, {
        id: uniqueID,
        accepted: true,
        timestamp: new Date(),
      });
      setShowTermsModal(false);
    } catch (error) {
      console.error('Error añadiendo documento: ', error);
    }
  };

  const handleRejectTerms = () => {
    alert('Debe aceptar los términos y condiciones para usar esta aplicación.');
  };

  return (
    <div className="App">

      <nav className="navbar">
        <div className="container-fluid">
          <button className="navbar-brand btn btn2">
            <Link to="/"><img src={logo} alt="Bootstrap" width="30" height="24" /></Link>
          </button>
          <div className='d-flex'>
            <Link to="/AboutUs" className='letra ' style={{ textDecoration: 'none' }}>
              <button className="btn btn-agenda letra">
                Sobre nosotros
              </button>
            </Link>
          </div>
        </div>
      </nav>
      <div className="content">
        <Carousel>
          {carouselImages.map((image, index) => (
            <CarouselItem key={index}>
              <img src={image.src} alt={image.alt} className="carousel-image" />
            </CarouselItem>
          ))}
        </Carousel>
      </div>

      <div className="quote">
        “Siempre había pensado que las viejas estaciones de ferrocarril eran uno de los pocos lugares mágicos que quedaban en el mundo. En ellas se mezclaban los fantasmas de recuerdos y despedidas con el inicio de cientos de viajes a destinos lejanos, sin retorno.
        "Si algun dia me pierdo, que me busquen en una estación de tren", pensé.”
        <p>
          - Carlos Ruiz Zafón, libro Cocaína.
        </p>
      </div>

      <div className="buttons-container">
        <Link to="/Recorrido" style={{ textDecoration: 'none', color: '#ffc107' }}>
          <button className="image-button" style={{
            backgroundImage: `url(${vrImage})`,
            fontWeight: 'bold'
          }}>
            <span>Recorrido Virtual</span>
          </button>
        </Link>
        <Link to="/agendaRecorrido" style={{ textDecoration: 'none', color: '#ffc107' }}>
          <button className="image-button" style={{
            backgroundImage: `url(${agendaImage})`,
            fontWeight: 'bold'
          }}>
            <span>Agenda y Eventos</span>
          </button>
        </Link>
        <Link to="/Tiempo" style={{ textDecoration: 'none', color: '#ffc107' }}>
          <button className="image-button" style={{
            backgroundImage: `url(${beforeAfterImage})`,
            fontWeight: 'bold'
          }}>
            <span>Antes y después</span>
          </button>
        </Link>
        <Link to="/Lugares" style={{ textDecoration: 'none', color: '#ffc107' }}>
          <button className="image-button" style={{
            backgroundImage: `url(${landmarksImage})`,
            fontWeight: 'bold'
          }}>
            <span>Lugares emblemáticos</span>
          </button>
        </Link>

      </div>

      <div className="sabias-que-container">
        <div className="sabias-que-content">
          <div className="sabias-que-text">
            <h1>¿Sabías que...?</h1>
            {sabiasQueData[currentSabiasQueIndex].text}
          </div>
          <div className="sabias-que-image">
            <img src={sabiasQueData[currentSabiasQueIndex].image} alt="Sabías que" />
          </div>
        </div>
        <div className="sabias-que-buttons">
          {sabiasQueData.map((item, index) => (
            <button
              key={index}
              className="sabias-que-button"
              onClick={() => handleSabiasQueChange(index)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
          <br></br>
      <div className="tcc-movil-container">
        <BootstrapCarousel className="BootstrapCarousel" interval={2000}>
          <BootstrapCarousel.Item >
            <img src={qrImage} alt="image" className="tcc-movil-qr" style={{width: '65%', marginLeft: '10%', marginRight: 'auto', display: 'block'}}/>
          </BootstrapCarousel.Item>
          <BootstrapCarousel.Item>
            <img src={qrImage1} alt="Código QR 2" className="tcc-movil-qr" style={{width: '50%', marginLeft: '25%', marginRight: 'auto', display: 'block' }} />
          </BootstrapCarousel.Item>
        </BootstrapCarousel>
        <div className="tcc-movil-text">
          <h2>TCC MÓVIL</h2>
          <p>Descubriras lo divertido que es aprender cultura dentro del museo, proximamente la aplicación movil de TCC</p>
        </div>
      </div>
      <div className="quedicen">
        <h1>QUÉ DICEN NUESTROS VISITANTES</h1>
      </div>
      <div className="comentarios-container">
        {comentariosUsuarios.map((comentario, index) => (
          <div key={index} className="comentario">
            <div className="comentario-header">
              <div className="comentario-usuario">
                <img src={tren} alt="Icono de tren" className="icono-tren" />
                {comentario.namePersona}
              </div>
            </div>
            <div className="comentario-fecha">{comentario.fechaHora}</div>
            <div className="comentario-texto">{comentario.comentario}</div>
          </div>
        ))}
      </div>
      <div class="palabra">
        <Button variant='warning' className="btn btn-warning palabra btn-agenda " data-bs-toggle="modal" data-bs-target="#Hola" style={{ fontWeight: 'bold' }}>
          Politicas de Privacidad
        </Button>
        <Button variant="warning" onClick={handleShow} className="btn btn-warning palabra btn-agenda" style={{ fontWeight: 'bold' }} >
          Equipo
        </Button>
        <Button variant="warning" className="btn btn-warning palabra btn-agenda" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ fontWeight: 'bold' }}>
          Terminos y Condiciones
        </Button>
      </div>
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Terminos y Condiciones</h5>
              <button type="button" class="btn-close " data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p className='Introduccion'>1- Introducción</p>
              <p className='modal-body'>
                Bienvenido a Tulancingo Culturalmente Conectado (TCC).
                Al utilizar nuestra plataforma interactiva y accesible, compuesta por una aplicación móvil y una
                aplicación web, usted acepta estos términos y condiciones. Por favor, léalos detenidamente.
              </p>
              <p className='Introduccion'>2- Definiciones</p>
              <ul className='modal-body'>
                <li>Plataforma: Se refiere tanto a la aplicación móvil como a la aplicación web de TCC.</li>
                <li>Usuario: Cualquier persona que acceda y utilice la plataforma.</li>
                <li>Contenido: Información, imágenes, videos, recorridos virtuales, y cualquier otro material disponible en la plataforma.</li>
              </ul>

              <p className='Introduccion'>3- Uso de la Plataforma</p>
              <p className='modal-body'>
                Los usuarios se comprometen a utilizar la plataforma de manera responsable y lícita.
                Está prohibido el uso de la plataforma para actividades ilegales, ofensivas o que infrinjan los
                derechos de terceros.
              </p>
              <p className='Introduccion'>4- Registro y Cuentas de Usuario</p>
              <p className='modal-body'>
                Para acceder a ciertas funciones, los usuarios pueden necesitar crear una cuenta.
                Es responsabilidad del usuario mantener la confidencialidad de su información de acceso y
                notificar cualquier uso no autorizado.
              </p>
              <p className='Introduccion'>5- Contenido Generado por el Usuario</p>
              <p className='modal-body'>
                Los usuarios pueden interactuar y contribuir con contenido. Al hacerlo, otorgan a TCC una
                licencia para usar, modificar y distribuir dicho contenido. Los usuarios son responsables del
                contenido que publican y deben asegurar que no infringe derechos de terceros.
              </p>
              <p className='Introduccion'>6- Propiedad Intelectual</p>
              <p className='modal-body'>
                Todo el contenido de la plataforma, incluyendo texto, imágenes, videos y recorridos virtuales,
                es propiedad de TCC o se utiliza con permiso. Los usuarios no pueden reproducir, distribuir ni
                crear obras derivadas sin autorización previa.
              </p>
              <p className='Introduccion'>7- Privacidad y Datos Personales</p>
              <p className='modal-body'>
                La recopilación y el uso de datos personales se rigen por nuestra Política de Privacidad,
                disponible más adelante en este documento.
              </p>
              <p className='Introduccion'>8- Limitación de Responsabilidad</p>
              <p className='modal-body'>
                TCC no se hace responsable de daños directos, indirectos o consecuentes derivados del uso
                de la plataforma. Los usuarios utilizan la plataforma bajo su propio riesgo.
              </p>
              <p className='Introduccion'>9- Modificaciones a los Términos y Condiciones</p>
              <p className='modal-body'>
                TCC se reserva el derecho de modificar estos términos y condiciones en cualquier momento.
                Los cambios se notificarán a los usuarios y el uso continuado de la plataforma constituirá la
                aceptación de los nuevos términos.
              </p>
              <p className='Introduccion'>10- Terminación</p>
              <p className='modal-body'>
                TCC puede terminar o suspender el acceso de un usuario a la plataforma por violar estos términos y
                condiciones o por cualquier otra razón justificada.
              </p>
              <p className='Introduccion'>11- Contacto</p>
              <p className='modal-body'>
                Para cualquier pregunta o inquietud sobre estos términos y condiciones, puede contactarnos
                en <a href='mailto:tccmuseos@gmail.com'>tccmuseos@gmail.com</a>
              </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="Hola" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Politicas de Privacidad</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p className='Introduccion'>1- Introducción</p>
              <p className='modal-body'>
                En TCC, valoramos su privacidad y nos comprometemos a proteger sus datos personales.
                Esta política describe cómo recopilamos, utilizamos y protegemos su información.
              </p>
              <p className='Introduccion'>2- Información que Recopilamos</p>
              <ul>
                <li>Información Personal: Nombre, dirección de correo electrónico, y otra información de contacto.</li>
                <li>Contenido Generado por el Usuario: Comentarios, contribuciones y cualquier otra información que los usuarios proporcionen.</li>
              </ul>
              <p className='Introduccion'>3- Uso de la Información</p>
              <p className='modal-body'>
                Utilizamos la información recopilada para:
                <ul>
                  <li>Proveer y mejorar nuestros servicios.</li>
                  <li>Personalizar la experiencia del usuario.</li>
                  <li>Comunicarnos con los usuarios sobre actualizaciones y novedades.</li>
                  <li>Analizar y entender cómo se utiliza nuestra plataforma.</li>
                </ul>
              </p>                <p className='Introduccion'>4- Compartir Información</p>
              <p className='modal-body'>
                No compartimos su información personal con terceros, excepto en los siguientes casos:
                <ul>
                  <li>Con proveedores de servicios que nos ayudan a operar la plataforma.</li>
                  <li>Cuando lo requiera la ley o en respuesta a solicitudes legales.</li>
                  <li>Para proteger nuestros derechos y seguridad.</li>
                </ul>
              </p>
              <p className='Introduccion'>5- Seguridad de la Información</p>
              <p className='modal-body'>
                Implementamos medidas de seguridad robustas para proteger la información del usuario contra accesos
                no autorizados, alteraciones, divulgaciones o destrucciones.
              </p>
              <p className='Introduccion'>6-  Derechos del Usuario</p>
              <p className='modal-body'>
                Los usuarios tienen derecho a:
                <ul>
                  <li>Acceder a su información personal.</li>
                  <li>Solicitar la corrección o eliminación de su información.</li>
                  <li>Oponerse al procesamiento de sus datos personales.</li>
                </ul>
              </p>
              <p className='Introduccion'>7- Cookies y Tecnologías Similares</p>
              <p className='modal-body'>
                Utilizamos cookies y tecnologías similares para mejorar la funcionalidad de la plataforma y
                personalizar la experiencia del usuario. Los usuarios pueden configurar su navegador para
                rechazar cookies, pero esto puede afectar algunas funcionalidades de la plataforma.
              </p>
              <p className='Introduccion'>8- Cambios en la Política de Privacidad</p>
              <p className='modal-body'>
                Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento.
                Los cambios se notificarán a los usuarios y el uso continuado de la plataforma constituirá la
                aceptación de la nueva política.
              </p>
              <p className='Introduccion'>9- Contacto</p>
              <p className='modal-body'>
                Para cualquier pregunta o inquietud sobre estos términos y condiciones, puede contactarnos
                en <a href='mailto:tccmuseos@gmail.com'>tccmuseos@gmail.com</a>
              </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Iniciar Sesión</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Correo Electrónico</Form.Label>
              <Form.Control
                type="email"
                placeholder="Ingrese su correo electrónico"
                value={correo}
                onChange={(e) => setCorreo(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Contraseña</Form.Label>
              <div className="password-container">
                <Form.Control
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Ingrese su contraseña"
                  value={contraseña}
                  onChange={(e) => setContraseña(e.target.value)}
                />
                <div className="password-icon" onClick={togglePasswordVisibility}>
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                </div>
              </div>
            </Form.Group>
            <Button variant="link" onClick={handleShowRecuperar}>
              ¿Olvidaste tu contraseña?
            </Button>
            <Button variant="primary" onClick={handleLogin}>
              Iniciar Sesión
            </Button>
            {error && <p className="text-danger">{error}</p>}
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showRecuperar} onHide={handleCloseRecuperar} centered>
        <Modal.Header closeButton>
          <Modal.Title>Recuperar Contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicEmailRecuperar">
              <Form.Label>Correo Electrónico</Form.Label>
              <Form.Control
                type="email"
                placeholder="Ingrese su correo electrónico"
                value={emailRecuperacion}
                onChange={(e) => setEmailRecuperacion(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" onClick={handleRecuperarContraseña}>
              Enviar Correo de Recuperación
            </Button>
            {error && <p className="text-danger">{error}</p>}
          </Form>
        </Modal.Body>
      </Modal>
      <p style={{ textAlign: "center" }}>&copy; 2024 TCC Todos los derechos reservados</p>

      <Modal show={showTermsModal} onHide={handleRejectTerms} centered>
        <Modal.Header>
          <Modal.Title>Lee y acepta nuestras condiciones</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '50vh', overflowY: 'auto' }}>
          <p className='Introduccion'>1- Aceptación y Uso</p>
          <p className='modal-body'>
            Al utilizar la plataforma TCC, los usuarios aceptan los términos y condiciones, y se comprometen a
            usarla de manera responsable y lícita. Esto incluye evitar actividades ilegales, ofensivas o que
            infrinjan los derechos de terceros.
          </p>
          <p className='Introduccion'>2- Registro, Cuentas y Privacidad:</p>
          <p className='modal-body'>
            Los usuarios pueden necesitar crear una cuenta para acceder a ciertas funciones. Son responsables de
            mantener la confidencialidad de su información de acceso. TCC recopila información personal, como nombre
            y correo electrónico, y se compromete a proteger estos datos según sus políticas de privacidad.
          </p>
          <p className='Introduccion'>3- Contenido Generado y Propiedad Intelectual</p>
          <p className='modal-body'>
            Los usuarios pueden contribuir con contenido a la plataforma, otorgando a TCC el derecho de usar y
            modificar dicho contenido. Además, todo el contenido de TCC es propiedad de TCC o se utiliza con permiso,
            y los usuarios no pueden reproducirlo sin autorización.
          </p>
          <p className='Introduccion'>4- Uso de Información y Seguridad</p>
          <p className='modal-body'>
            La información recopilada se utiliza para mejorar los servicios, personalizar la experiencia del usuario y
            comunicarse con los usuarios. TCC implementa medidas de seguridad para proteger la información del usuario
            contra accesos no autorizados y otros riesgos.
          </p>
          <p className='Introduccion'>5- Modificaciones, Terminación y Derechos del Usuario</p>
          <p className='modal-body'>
            TCC se reserva el derecho de modificar los términos y condiciones, y las políticas de privacidad. Los cambios
            se notificarán a los usuarios. TCC puede terminar o suspender el acceso de un usuario por incumplimiento de estos
            términos. Los usuarios tienen derecho a acceder, corregir, eliminar su información personal y oponerse al
            procesamiento de sus datos.
          </p>
          <p className='modal-body'>
            Para cualquier pregunta o inquietud sobre la información, puede contactarnos
            en <a href='mailto:tccmuseos@gmail.com'>tccmuseos@gmail.com</a>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleRejectTerms}>
            Rechazar
          </Button>
          <Button variant="primary" onClick={handleAcceptTerms}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default App;
