import React, { useState } from 'react';
import '../CSS/recorrido.css';
import { Link } from 'react-router-dom'; 
import logo from '../../components/Imagenes/Recurso.png';
import { db } from '../../DB/firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { Alert } from 'react-bootstrap';

const RecorridoVirtual = () => {
    const [nombre, setNombre] = useState('');
    const [comentario, setComentario] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const validateForm = () => {
        if (!nombre || !comentario) {
            setError('Por favor, complete todos los campos.');
            return false;
        }
        setError('');
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            const docRef = await addDoc(collection(db, 'visitasComentarios'), {
                namePersona: nombre,
                comentario: comentario,
                fechaHora: serverTimestamp(),
                estado: 0,
            });
            console.log('Documento escrito con ID: ', docRef.id);
            setSuccess('Comentario guardado exitosamente');
            setNombre('');
            setComentario('');
            setTimeout(() => {
                setSuccess('');
            }, 1000);
        } catch (e) {
            console.error('Error añadiendo documento: ', e);
            setError('Error guardando el comentario');
            setTimeout(() => {
                setError('');
            }, 1000);
        }
    };

    return (
        <div>
            <nav className="navbar">
                <div className="container-fluid">
                    <button className="navbar-brand btn btn2">
                        <Link to="/"><img src={logo} alt="Bootstrap" width="30" height="24" /></Link>
                    </button>
                    <div className='d-flex'>
                        <Link to="/" className='letra ' style={{ textDecoration: 'none' }}>
                            <button className="btn btn-agenda letra">
                                Inicio
                            </button>
                        </Link>
                    </div>
                </div>
            </nav>
            <div className="recorrido container-fluid">
                <div className="virtual-tour text-center">
                    <h1 className='titulor'>Recorrido virtual</h1>
                    <div className="tour-playing">
                        <div className='container-fluid d-flex row'>
                        <iframe class="ku-embed" frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowfullscreen scrolling="no" src="https://kuula.co/share/collection/7KcvW?logo=1&card=1&info=0&logosize=62&fs=1&vr=1&zoom=1&initload=0&thumbs=-1&alpha=0.60&inst=es"></iframe>
                        </div>
                        <div className="comentario-form container-xxl">
                            <h2 className='subtitulor'>Queremos saber tu opinión</h2>
                            <form onSubmit={handleSubmit} className="form">
                                {error && <Alert variant="danger">{error}</Alert>}
                                {success && <Alert variant="success">{success}</Alert>}
                                <div className="form-group mb-3">
                                    <label htmlFor="name" className='form-label labelr'>Nombre</label>
                                    <input
                                        type="text"
                                        id="name"
                                        placeholder='Nombre'
                                        className="form-control"
                                        value={nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                        autoFocus
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="comment" className='form-label labelr'>Comentario</label>
                                    <textarea
                                        id="comment"
                                        placeholder='El recorrido me parecio...'
                                        className="form-control"
                                        value={comentario}
                                        onChange={(e) => setComentario(e.target.value)}
                                        required
                                    />
                                </div>
                                <button type="submit" className="bnt botonComentario" style={{ backgroundColor: '#ffdf1b' }}>
                                    Compartir
                                </button>
                                {error && <div className="alert alert-danger mt-3">{error}</div>}
                            </form>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        </div>
    );
};

export default RecorridoVirtual;
