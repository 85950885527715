import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import AgendaRecorrido from './Usuario/Secciones/agendaRecorrido';
import Lugares from './Usuario/Secciones/Lugares';
import AboutUs from './Usuario/Secciones/AboutUs';
import Tiempo from './Usuario/Secciones/AntesyDespues';
import Recorrido from './Usuario/Secciones/recorrido';
import InicioAdmin from './Administrador/Secciones/inicioAdmin';
import AntesDespues from './Administrador/Secciones/GestionAyD';
import GestionVisitas from './Administrador/Secciones/GestionVistas';
import LugEmblematicos from './Administrador/Secciones/LugaresE';
import RecorridoV from './Administrador/Secciones/RecorridoVir';
import RecorridoEvento from './Administrador/Secciones/RecorryEven';
import ProtectedRoute from './ruta/ProtectedRoute';
import { AuthProvider } from './ruta/AuthContext';
import LoginModal from './ruta/LoginModal';

ReactDOM.render(
  <AuthProvider>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/agendaRecorrido" element={<AgendaRecorrido />} />
        <Route path="/Tiempo" element={<Tiempo />} />
        <Route path="/Lugares" element={<Lugares />} />
        <Route path="/Recorrido" element={<Recorrido />} />
        <Route path="/login" element={<LoginModal />} />
        <Route path="/InicioAdmin" element={<ProtectedRoute element={<InicioAdmin />} />} />
        <Route path="/AntesDespues" element={<ProtectedRoute element={<AntesDespues />} />} />
        <Route path="/GestionVisitas" element={<ProtectedRoute element={<GestionVisitas />} />} />
        <Route path="/LugEmblematicos" element={<ProtectedRoute element={<LugEmblematicos />} />} />
        <Route path="/RecorridoV" element={<ProtectedRoute element={<RecorridoV />} />} />
        <Route path="/RecorridoEvento" element={<ProtectedRoute element={<RecorridoEvento />} />} />
      </Routes>
    </Router>
  </AuthProvider>,
  document.getElementById('root')
);
