import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, addDoc, updateDoc, deleteDoc, getDocs, doc } from 'firebase/firestore';
import { db } from '../../DB/firebase';
import logo1 from '../../components/Imagenes/Recurso.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import '../CSS/RecorryEven.css';
import emailjs from 'emailjs-com';
import agregar from '../../components/Imagenes/agregar.png';
import aprobe from '../../components/Imagenes/seleccionar.png';
import editar from '../../components/Imagenes/editar.png';
import eliminar from '../../components/Imagenes/eliminar.png';

function RecorridoEvento() {
  const [events, setEvents] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [editingEvent, setEditingEvent] = useState(null);
  const [newEvent, setNewEvent] = useState({
    image: '', title: '', shortDescription: '', longDescription: '', date: '', approved: false
  });
  const [message, setMessage] = useState('');
  const [approveMessage, setApproveMessage] = useState('');

  useEffect(() => {
    const fetchEvents = async () => {
      const querySnapshot = await getDocs(collection(db, "eventos"));
      const eventsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEvents(eventsList);
    };

    const fetchReservations = async () => {
      const querySnapshot = await getDocs(collection(db, "agenda"));
      const reservationsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setReservations(reservationsList);
    };

    fetchEvents();
    fetchReservations();
  }, []);

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const base64Image = await toBase64(file);
      setNewEvent({ ...newEvent, image: base64Image });
    }
  };

  const handleEditImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const base64Image = await toBase64(file);
      setEditingEvent({ ...editingEvent, image: base64Image });
    }
  };

  const handleAddEvent = async () => {
    const eventWithApproval = { ...newEvent, approved: false };
    const docRef = await addDoc(collection(db, "eventos"), eventWithApproval);
    setEvents([...events, { ...eventWithApproval, id: docRef.id }]);
    setNewEvent({ image: '', title: '', shortDescription: '', longDescription: '', date: '', approved: false });
    setMessage('Evento agregado exitosamente');
    setTimeout(() => setMessage(''), 3000);
  };

  const handleSaveChanges = async () => {
    const eventRef = doc(db, "eventos", editingEvent.id);
    await updateDoc(eventRef, editingEvent);
    setEvents(events.map(event => event.id === editingEvent.id ? editingEvent : event));
    setEditingEvent(null);
    setMessage('Cambios guardados exitosamente');
    setTimeout(() => setMessage(''), 3000);
  };

  const handleApprove = async (event) => {
    const eventRef = doc(db, "eventos", event.id);
    await updateDoc(eventRef, { approved: true });
    setEvents(events.map(e => e.id === event.id ? { ...e, approved: true } : e));
    setApproveMessage('Evento aprobado exitosamente');
    setTimeout(() => setApproveMessage(''), 3000);
  };

  const handleEditClick = (event) => {
    setEditingEvent(event);
  };

  const handleDeleteClick = async (id) => {
    await deleteDoc(doc(db, "eventos", id));
    setEvents(events.filter(event => event.id !== id));
    setMessage('Evento eliminado exitosamente');
    setTimeout(() => setMessage(''), 3000);
  };

  const handleShowDetails = (reservation) => {
    setSelectedReservation(reservation);
  };

  const handleCloseDetails = () => {
    setSelectedReservation(null);
  };

  const sendEmail = async (to, subject, body) => {
    try {
      const templateParams = {
        to_email: to,
        subject: subject,
        message: body,
      };

      await emailjs.send(
        'service_83usdyt',
        'template_6az992f',
        templateParams,
        'EKZPAg81uSbiu8ure'
      );

      console.log('Correo enviado exitosamente');
    } catch (error) {
      console.error('Error al enviar el correo:', error);
    }
  };


  const handleAcceptReservation = async () => {
    if (selectedReservation) {
      const subject = 'Reserva Aceptada';
      const body = `Su reserva para el museo ${selectedReservation.museo} el día ${new Date(selectedReservation.fechaYHora).toLocaleString()} ha sido aceptada.`;
      await sendEmail(selectedReservation.email, subject, body);
      setMessage('Reserva aceptada y correo enviado.');
      setTimeout(() => setMessage(''), 3000);

      // Eliminar la reserva de Firestore
      await deleteDoc(doc(db, "agenda", selectedReservation.id));

      // Actualizar el estado para eliminar la reserva aceptada
      setReservations(reservations.filter(res => res.id !== selectedReservation.id));
      handleCloseDetails();
    }
  };

  const handleRejectReservation = async () => {
    if (selectedReservation) {
      const subject = 'Reserva Rechazada';
      const body = `Lamentamos informarle que su reserva para el museo ${selectedReservation.museo} el día ${new Date(selectedReservation.fechaYHora).toLocaleString()} ha sido rechazada, puede volver a enviar otra solicitud`;
      await sendEmail(selectedReservation.email, subject, body);
      setMessage('Reserva rechazada y correo enviado.');
      setTimeout(() => setMessage(''), 3000);

      // Eliminar la reserva de Firestore
      await deleteDoc(doc(db, "agenda", selectedReservation.id));

      // Actualizar el estado para eliminar la reserva rechazada
      setReservations(reservations.filter(res => res.id !== selectedReservation.id));
      handleCloseDetails();
    }
  };

  return (
    <div>
      <nav className="navbar">
        <div className="container-fluid">
          <button className="navbar-brand btn btn2">
            <Link to="/InicioAdmin"><img src={logo1} alt="Bootstrap" width="40" height="35" /></Link>
          </button>
          <div className='d-flex'>
            <Link to="/InicioAdmin" className='letra' style={{ textDecoration: 'none' }}>
              <button className="btn btn-agenda" type='button'>
                Inicio
              </button>
            </Link>
          </div>
        </div>
      </nav>
      <h1 className='bienvenido'>Gestión de Eventos y Agenda</h1>
      {message && <div className="alert alert-success" role="alert">{message}</div>}
      {approveMessage && <div className="alert alert-info" role="alert">{approveMessage}</div>}
      <div className='ContenedorCuadro'>
        <div className='programa'>
          <h1 className='SubtituloP'>Programa de Evento</h1>
          {events.map(event => (
            <div key={event.id} className='evento'>
              <img src={event.image} alt="Evento" className='eventoImagen' />
              <div className='eventoDetalles'>
                <h2 className='eventoTitulo'>{event.title}</h2>
                <p className='SubtituloNX'>{event.shortDescription}</p>
                <p className='SubtituloNX'>{event.longDescription}</p>
                <p className='eventoFecha'>
                  <FontAwesomeIcon icon={faCalendar} size='1x' className='IconoXD' />
                  {event.date}
                </p>
                <div className='eventoBotones'>
                  <button className='btn btn-primary color1' onClick={() => handleEditClick(event)}><img src={editar} alt="agregar"  width="20" height="20" /> Editar</button>
                  <button className='btn btn-secondary color2' onClick={() => handleDeleteClick(event.id)}><img src={eliminar } alt="eliminar" width="20" height="20" /> Eliminar</button>
                  <button className='btn btn-primary color1' id='aprobarevento1' onClick={() => handleApprove(event)}><img src={aprobe} alt="agregar"  width="20" height="20" /> Aprobar</button>
                </div>
              </div>
            </div>
          ))}
          <button className='btn btn-primary nuevoEvento' data-bs-toggle="modal" data-bs-target="#addEventModal">
          <img src={agregar} alt="agregar"  width="20" height="20" /> Crear un nuevo evento
          </button>
        </div>
        {/* Sección de Programa de Recorridos */}
        <div className='programa1'>
          <h1 className='SubtituloP'>Programa de Recorridos</h1>
          {reservations.map(reservation => (
            <div key={reservation.id} className='recorrido'>
              <p className='recorridoFecha'>
                <FontAwesomeIcon icon={faCalendar} className='RecorridoIcono' />
                {new Date(reservation.fechaYHora).toLocaleString()}
              </p>
              <div className='recorridoDetalles'>
                <h2 className='recorridoPersona'>{reservation.nombre}</h2>
                <p className='SubtituloNX'>{reservation.numPersonas} personas</p>
                <button className='btn btn-primary margenver color1' onClick={() => handleShowDetails(reservation)}>Ver</button>
              </div>
            </div>
          ))}
        </div> 
      </div>
      {/* Modal para ver la información detallada de la agenda */}
      {selectedReservation && (
        <div className="modal fade show" style={{ display: 'block' }} role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header n">
                <h5 className="modal-title">Detalles de la Reserva</h5>
                <button type="button" className="btn-close" onClick={handleCloseDetails} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p className='centrado'>Museo: {selectedReservation.museo}</p>
                <p className='centrado'>Fecha y Hora: {new Date(selectedReservation.fechaYHora).toLocaleString()}</p>
                <p className='centrado'>Nombre: {selectedReservation.nombre}</p>
                <p className='centrado'>Correo: {selectedReservation.email}</p>
                <p className='centrado'>Número de personas: {selectedReservation.numPersonas}</p>
                <p className='centrado'>Género F: {selectedReservation.generoF}</p>
                <p className='centrado'>Género M: {selectedReservation.generoM}</p>
                <p className='centrado'>Género O: {selectedReservation.generoO}</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn  color1" onClick={handleAcceptReservation}>Aceptado</button>
                <button type="button" className="btn  color2" onClick={handleRejectReservation}>Rechazado</button>
                <button type="button" className="btn  color1" onClick={handleCloseDetails}>Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Modal para agregar evento */}
      <div className="modal fade" id="addEventModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header n">
              <h5 className="modal-title" id="exampleModalLabel">Agregar Evento</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className='form-group'>
                <label>Título del Evento</label>
                <input type="text" className='form-control' value={newEvent.title} onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })} />
              </div>
              <div className='form-group'>
                <label>Descripción Corta</label>
                <input type="text" className='form-control' value={newEvent.shortDescription} onChange={(e) => setNewEvent({ ...newEvent, shortDescription: e.target.value })} />
              </div>
              <div className='form-group'>
                <label>Descripción Larga</label>
                <input type="text" className='form-control' value={newEvent.longDescription} onChange={(e) => setNewEvent({ ...newEvent, longDescription: e.target.value })} style={{ textAlign: "justify" }} />
              </div>
              <div className='form-group'>
                <label>Fecha del Evento</label>
                <input type="date" className='form-control' value={newEvent.date} onChange={(e) => setNewEvent({ ...newEvent, date: e.target.value })} />
              </div>
              <div className='form-group'>
                <label>Imagen del Evento</label>
                <input type="file" className='form-control' onChange={handleImageChange} />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn  color2" data-bs-dismiss="modal">Cerrar</button>
              <button type="button" className="btn  color1" id='GuardarEvento' onClick={handleAddEvent}>Guardar</button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal para editar evento */}
      {editingEvent && (
        <div className="modal fade show" id="editEventModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header n">
                <h5 className="modal-title">Editar Evento</h5>
                <button type="button" className="btn-close" onClick={() => setEditingEvent(null)} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className='form-group'>
                  <label>Título del Evento</label>
                  <input type="text" className='form-control' value={editingEvent.title} onChange={(e) => setEditingEvent({ ...editingEvent, title: e.target.value })} />
                </div>
                <div className='form-group'>
                  <label>Descripción Corta</label>
                  <input type="text" className='form-control' value={editingEvent.shortDescription} onChange={(e) => setEditingEvent({ ...editingEvent, shortDescription: e.target.value })} />
                </div>
                <div className='form-group'>
                  <label>Descripción Larga</label>
                  <input type="text" className='form-control' value={editingEvent.longDescription} onChange={(e) => setEditingEvent({ ...editingEvent, longDescription: e.target.value })} />
                </div>
                <div className='form-group'>
                  <label>Fecha del Evento</label>
                  <input type="date" className='form-control' value={editingEvent.date} onChange={(e) => setEditingEvent({ ...editingEvent, date: e.target.value })} />
                </div>
                <div className='form-group'>
                  <label>Imagen del Evento</label>
                  <input type="file" className='form-control' onChange={handleEditImageChange} />
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn  color2" onClick={() => setEditingEvent(null)}>Cancelar</button>
                <button type="button" className="btn  color1" onClick={handleSaveChanges}>Guardar Cambios</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RecorridoEvento;
