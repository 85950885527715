import React, { useState, useEffect, useRef } from 'react';
import '../CSS/Lugares.css';
import logo from '../../components/Imagenes/Recurso.png';
import { Link } from 'react-router-dom';
import { db } from '../../DB/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

function Lugares() {
    const [lugares, setLugares] = useState([]);
    const containerRefs = useRef([]);

    useEffect(() => {
        const fetchLugares = async () => {
            const q = query(collection(db, 'lugaresEmblematicos'), where('publicado', '==', true));
            const querySnapshot = await getDocs(q);
            const lugaresData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setLugares(lugaresData);
        };

        fetchLugares();
    }, []);

    useEffect(() => {
        const resizeContainers = () => {
            let maxHeight = 0;
            containerRefs.current.forEach(ref => {
                if (ref) {
                    ref.style.height = 'auto'; // Reset height to auto to get actual height
                    const height = ref.getBoundingClientRect().height;
                    if (height > maxHeight) {
                        maxHeight = height;
                    }
                }
            });
            containerRefs.current.forEach(ref => {
                if (ref) {
                    ref.style.height = `${maxHeight}px`;
                }
            });
        };

        resizeContainers();
        window.addEventListener('resize', resizeContainers);
        return () => window.removeEventListener('resize', resizeContainers);
    }, [lugares]);

    return (
        <div>
            <nav className="navbar">
                <div className="container-fluid">
                    <button className="navbar-brand btn btn2">
                        <Link to="/"><img src={logo} alt="Bootstrap" width="30" height="24" /></Link>
                    </button>
                    <div className='d-flex'>
                        <Link to="/" className='letra' style={{ textDecoration: 'none' }}>
                            <button className="btn btn-agenda letra">
                                Inicio
                            </button>
                        </Link>
                    </div>
                </div>
            </nav>
            <h1 className='titulo'>Lugares Emblemáticos de Tulancingo</h1>
            <div className="grid-container">
                {lugares.map((lugar, index) => (
                    <div key={lugar.id} className="grid-item">
                        <div className="content-wrapper" ref={el => containerRefs.current[index] = el}>
                            <div className="imag-container">
                                <img src={lugar.imagen} className="imag" alt="lugar" />
                            </div>
                            <p className='text'>
                                {lugar.descripcion}<br />
                                <a href={lugar.ubicacion} target="_blank" rel="noopener noreferrer">Ver en Google Maps</a>
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Lugares;
