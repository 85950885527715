// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, setPersistence, browserLocalPersistence, signOut } from 'firebase/auth';
import { getStorage } from "firebase/storage";  

const firebaseConfig = {
  apiKey: "AIzaSyChLH3i5BdAZBrbyYskaKlTgMfgRI3iTk4",
  authDomain: "tcc-web-af4e6.firebaseapp.com",
  databaseURL: "https://tcc-web-af4e6-default-rtdb.firebaseio.com",
  projectId: "tcc-web-af4e6",
  storageBucket: "tcc-web-af4e6.appspot.com",
  messagingSenderId: "784836322433",
  appId: "1:784836322433:web:6d8f68047370d0fc989980",
  measurementId: "G-Z7649V8SSR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

setPersistence(auth, browserLocalPersistence).catch((error) => {
  console.error("Error setting persistence:", error);
});

export { db, auth, storage, signOut };