import '../CSS/inicioAdmin.css';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo1 from '../../components/Imagenes/Recurso.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCamera, faLocationDot, faUsers, faVrCardboard } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from 'react-bootstrap';

const InicioAdmin = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate('/');
  };

  useEffect(() => {
    const preventBackNavigation = () => {
      window.history.pushState(null, '', window.location.href);
      window.addEventListener('popstate', () => {
        window.history.pushState(null, '', window.location.href);
      });
    };

    preventBackNavigation();
  }, []);

  return (
    <div className="login-container">
      <nav className="navbar">
        <div className="container-fluid">
          <button className="navbar-brand btn btn2">
            <Link to="/InicioAdmin">
              <img src={logo1} alt="Bootstrap" width="40" height="35" />
            </Link>
          </button>
          <div className="d-flex">
            <button
              className="btn btn-agenda"
              type="button"
              onClick={() => setShowLogoutModal(true)}
            >
              Cerrar sesión
            </button>
          </div>
        </div>
      </nav>
      <main>
        <div>
          <h1 className="bienvenido">Bienvenid@</h1>
          <div className="cuadros-container">
            <div className="cuadro">
              <FontAwesomeIcon icon={faUsers} size="2x" />
              <h2>Reporte de Visitas y Comentarios</h2>
              <p>150 visitantes</p>
              <Link to="/GestionVisitas">
                <Button variant="warning" className='btnAdmin'>Acción</Button>
              </Link>
            </div>
            <div className="cuadro">
              <FontAwesomeIcon icon={faVrCardboard} size="2x" />
              <h2>Recorrido Virtual y Narrativa</h2>
              <p>Actualización</p>
              <Link to="/RecorridoV">
                <Button variant="warning" className='btnAdmin'>Acción</Button>
              </Link>
            </div>
            <div className="cuadro">
              <FontAwesomeIcon icon={faCamera} size="2x" />
              <h2>Antes y Después</h2>
              <p>Fotos de Tulancingo</p>
              <Link to="/AntesDespues">
                <Button variant="warning" className='btnAdmin'>Acción</Button>
              </Link>
            </div>
            <div className="cuadro">
              <FontAwesomeIcon icon={faLocationDot} size="2x" />
              <h2>Lugares Emblematicos</h2>
              <p>Lugares Importantes de Tulancingo</p>
              <Link to="/LugEmblematicos">
                <Button variant="warning" className='btnAdmin'>Acción</Button>
              </Link>
            </div>
            <div className="cuadro">
              <FontAwesomeIcon icon={faCalendarAlt} size="2x" />
              <h2>Agenda de Recorridos y Eventos</h2>
              <p>Recorridos y Eventos de Tulancingo</p>
              <Link to="/RecorridoEvento">
                <Button variant="warning" className='btnAdmin'>Acción</Button>
              </Link>
            </div>
          </div>
        </div>
      </main>
      <Modal show={showLogoutModal} onHide={() => setShowLogoutModal(false)}>
        <Modal.Body>
          ¿Estás seguro de que deseas cerrar sesión?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLogoutModal(false)}>Cancelar</Button>
          <Button variant="primary" onClick={handleLogout}>Cerrar sesión</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default InicioAdmin;
