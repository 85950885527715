// Carousel.js

import React, { useState, useEffect } from 'react';
import './Carousel.css'; // Importa los estilos del carrusel

const Carousel = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const totalSlides = React.Children.count(children);

  // Función para avanzar al siguiente slide
  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex === totalSlides - 1 ? 0 : prevIndex + 1));
  };

  // Función para retroceder al slide anterior
  const prevSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex === 0 ? totalSlides - 1 : prevIndex - 1));
  };

  // Efecto para cambiar de slide automáticamente cada 3 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000);

    return () => clearInterval(interval); // Limpiar el intervalo al desmontar el componente
  }, []);

  return (
    <div className="carousel-container">
      <div className="carousel">
        {React.Children.map(children, (child, index) => (
          <div className={index === activeIndex ? 'carousel-item active' : 'carousel-item'}>
            {child}
          </div>
        ))}
      </div>
      <button className="carousel-btn carousel-prev" onClick={prevSlide}>
        &#8249; {/* Flecha hacia la izquierda */}
      </button>
      <button className="carousel-btn carousel-next" onClick={nextSlide}>
        &#8250; {/* Flecha hacia la derecha */}
      </button>
    </div>
  );
};

export const CarouselContent = ({ children }) => {
  return <div className="carousel-content">{children}</div>;
};

export const CarouselItem = ({ children }) => {
  return <div className="carousel-image">{children}</div>;
};

export default Carousel;
