import '../CSS/AboutUs.css';
import logo from '../../components/Imagenes/Recurso.png';
import { Link } from 'react-router-dom';
import imagen from '../../components/Imagenes/equipo.png';
import imagen1 from '../../components/Imagenes/mision-vision-image.png';
import imagen2 from '../../components/Imagenes/valores-marca-web.png';
import facebook from '../../components/Imagenes/facebook.png';
import instagram from '../../components/Imagenes/instagram.png';
import { useState } from 'react';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import { db } from '../../DB/firebase';
import { collection, addDoc } from 'firebase/firestore';
import emailjs from 'emailjs-com';

function Panda() {
  const [show, setShow] = useState(false);
  const [nombre, setNombre] = useState('');
  const [correo, setCorreo] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [error, setError] = useState('');

  const handleClose = () => {
    setShow(false);
    setError('');
    setNombre('');
    setCorreo('');
    setMensaje('');
  };

  const handleShow = () => setShow(true);

  const validateForm = () => {
    if (!nombre || !correo || !mensaje) {
      setError('Por favor, complete todos los campos.');
      return false;
    }
    if (!correo.includes('@')) {
      setError('El correo debe contener un símbolo de arroba (@).');
      return false;
    }
    setError('');
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
  
    try {
      // Guarda en Firebase
      const docRef = await addDoc(collection(db, "contactos"), {
        nombre: nombre,
        correo: correo,
        mensaje: mensaje,
      });
      console.log("Documento escrito con ID: ", docRef.id);
  
      // Envía el correo electrónico
      const templateParams = {
        to_name: 'Museo', 
        from_name: nombre, 
        message: mensaje, 
        reply_to: correo   
      };
  
      await emailjs.send('service_83usdyt', 'template_cxc6wvs', templateParams, 'EKZPAg81uSbiu8ure');
      console.log("Correo enviado exitosamente");
  
      handleClose();
    } catch (e) {
      console.error("Error añadiendo documento o enviando correo: ", e);
    }
  };
  

  return (
    <div >
      <nav className="navbar">
        <div className="container-fluid">
          <button className="navbar-brand btn btn2">
            <Link to="/"><img src={logo} alt="Bootstrap" width="30" height="24" /></Link>
          </button>
          <div className='d-flex'>
            <Link to="/" className='letra' style={{ textDecoration: 'none' }}>
              <button className="btn btn-agenda letra">
                Inicio
              </button>
            </Link>
          </div>
        </div>
      </nav>
      <body>
        <h1 className='texto'> <img src={imagen} className="imagen" alt="logo" />
          Sobre nosotros</h1>

        <p className='texto1'>
          Somos tres estudiantes dedicados a revitalizar el interés cultural en nuestro municipio.
          A través de nuestro aplicativo sobre museos locales, buscamos ayudar a la comunidad a
          reconectar con su rica herencia cultural. Ofrecemos información accesible sobre exposiciones
          y eventos, mejorando la experiencia del visitante tanto en el sitio como en casa.
        </p>

        <h2 className='texto2'> <img src={imagen1} className="imagen1" alt="logo" />
          Nuestra Visión</h2>

        <p className='texto3'>
          Crear una comunidad activa y conectada con su herencia cultural, haciendo que el arte y
          la historia sean accesibles para todos. Queremos que cada visita a los museos locales sea
          una experiencia enriquecedora, inspirando a las futuras generaciones a valorar y preservar
          nuestra cultura.
        </p>

        <h2 className='texto'> <img src={imagen2} className="imagen" alt="logo" />
          Valores</h2>

        <p className='texto3'>
          <li>Accesibilidad: Arte y cultura para todos.</li> <br></br>
          <li>Conexión: Vinculamos a la comunidad con su patrimonio.</li><br></br>
          <li>Innovación: Mejoramos la experiencia cultural con tecnología.</li>
        </p>

        {/*<h2 className='texto2'> Oportunidades de Colaboración</h2>
        <p className='texto4'>
          Si deseas ser parte de nuestro equipo, ya sea como donante o patrocinador, te invitamos
          a que te comuniques con nosotros. Juntos podemos hacer la diferencia en nuestra comunidad.
        </p> */}

        <div class="social-icons">
          <Button variant="primary" onClick={handleShow} id='openModalBtn'>
            Contactanos
          </Button>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Contactanos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="nombre completo"
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                    autoFocus
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Correo</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    value={correo}
                    onChange={(e) => setCorreo(e.target.value)}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Mensaje</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={mensaje}
                    onChange={(e) => setMensaje(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cerrar
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Enviar
              </Button>
            </Modal.Footer>
          </Modal>
          <a href="https://www.facebook.com">
            <i> <img src={facebook} className="imagen1" alt="logo" /> </i>
          </a>
          <a href="https://instagram.com">
            <i> <img src={instagram} className="imagen1" alt="logo" /> </i>
          </a>
        </div>
        <hr />
        <button type="button" class="btn btn-primary texto5" data-bs-toggle="modal" data-bs-target="#exampleModal">
          Terminos y Condiciones
        </button>
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Terminos y Condiciones</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <p className='Introduccion'>1- Introducción</p>
                <p className='modal-body'>
                  Bienvenido a Tulancingo Culturalmente Conectado (TCC).
                  Al utilizar nuestra plataforma interactiva y accesible, compuesta por una aplicación móvil y una
                  aplicación web, usted acepta estos términos y condiciones. Por favor, léalos detenidamente.
                </p>
                <p className='Introduccion'>2- Definiciones</p>
                <ul className='modal-body'>
                  <li>Plataforma: Se refiere tanto a la aplicación móvil como a la aplicación web de TCC.</li>
                  <li>Usuario: Cualquier persona que acceda y utilice la plataforma.</li>
                  <li>Contenido: Información, imágenes, videos, recorridos virtuales, y cualquier otro material disponible en la plataforma.</li>
                </ul>

                <p className='Introduccion'>3- Uso de la Plataforma</p>
                <p className='modal-body'>
                  Los usuarios se comprometen a utilizar la plataforma de manera responsable y lícita.
                  Está prohibido el uso de la plataforma para actividades ilegales, ofensivas o que infrinjan los
                  derechos de terceros.
                </p>
                <p className='Introduccion'>4- Registro y Cuentas de Usuario</p>
                <p className='modal-body'>
                  Para acceder a ciertas funciones, los usuarios pueden necesitar crear una cuenta.
                  Es responsabilidad del usuario mantener la confidencialidad de su información de acceso y
                  notificar cualquier uso no autorizado.
                </p>
                <p className='Introduccion'>5- Contenido Generado por el Usuario</p>
                <p className='modal-body'>
                  Los usuarios pueden interactuar y contribuir con contenido. Al hacerlo, otorgan a TCC una
                  licencia para usar, modificar y distribuir dicho contenido. Los usuarios son responsables del
                  contenido que publican y deben asegurar que no infringe derechos de terceros.
                </p>
                <p className='Introduccion'>6- Propiedad Intelectual</p>
                <p className='modal-body'>
                  Todo el contenido de la plataforma, incluyendo texto, imágenes, videos y recorridos virtuales,
                  es propiedad de TCC o se utiliza con permiso. Los usuarios no pueden reproducir, distribuir ni
                  crear obras derivadas sin autorización previa.
                </p>
                <p className='Introduccion'>7- Privacidad y Datos Personales</p>
                <p className='modal-body'>
                  La recopilación y el uso de datos personales se rigen por nuestra Política de Privacidad,
                  disponible más adelante en este documento.
                </p>
                <p className='Introduccion'>8- Limitación de Responsabilidad</p>
                <p className='modal-body'>
                  TCC no se hace responsable de daños directos, indirectos o consecuentes derivados del uso
                  de la plataforma. Los usuarios utilizan la plataforma bajo su propio riesgo.
                </p>
                <p className='Introduccion'>9- Modificaciones a los Términos y Condiciones</p>
                <p className='modal-body'>
                  TCC se reserva el derecho de modificar estos términos y condiciones en cualquier momento.
                  Los cambios se notificarán a los usuarios y el uso continuado de la plataforma constituirá la
                  aceptación de los nuevos términos.
                </p>
                <p className='Introduccion'>10- Terminación</p>
                <p className='modal-body'>
                  TCC puede terminar o suspender el acceso de un usuario a la plataforma por violar estos términos y
                  condiciones o por cualquier otra razón justificada.
                </p>
                <p className='Introduccion'>11- Contacto</p>
                <p className='modal-body'>
                  Para cualquier pregunta o inquietud sobre estos términos y condiciones, puede contactarnos
                  en <a href='mailto:tccmuseos@gmail.com'>tccmuseos@gmail.com</a>
                </p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
        <button type="button" class="btn btn-primary texto5" data-bs-toggle="modal" data-bs-target="#Hola">
          Politicas de Privacidad
        </button>
        <div class="modal fade" id="Hola" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Politicas de Privacidad</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <p className='Introduccion'>1- Introducción</p>
                <p className='modal-body'>
                  En TCC, valoramos su privacidad y nos comprometemos a proteger sus datos personales.
                  Esta política describe cómo recopilamos, utilizamos y protegemos su información.
                </p>
                <p className='Introduccion'>2- Información que Recopilamos</p>
                <ul>
                  <li>Información Personal: Nombre, dirección de correo electrónico, y otra información de contacto.</li>
                  <li>Contenido Generado por el Usuario: Comentarios, contribuciones y cualquier otra información que los usuarios proporcionen.</li>
                </ul>
                <p className='Introduccion'>3- Uso de la Información</p>
                <p className='modal-body'>
                  Utilizamos la información recopilada para:
                  <ul>
                    <li>Proveer y mejorar nuestros servicios.</li>
                    <li>Personalizar la experiencia del usuario.</li>
                    <li>Comunicarnos con los usuarios sobre actualizaciones y novedades.</li>
                    <li>Analizar y entender cómo se utiliza nuestra plataforma.</li>
                  </ul>
                </p>                <p className='Introduccion'>4- Compartir Información</p>
                <p className='modal-body'>
                  No compartimos su información personal con terceros, excepto en los siguientes casos:
                  <ul>
                    <li>Con proveedores de servicios que nos ayudan a operar la plataforma.</li>
                    <li>Cuando lo requiera la ley o en respuesta a solicitudes legales.</li>
                    <li>Para proteger nuestros derechos y seguridad.</li>
                  </ul>
                </p>
                <p className='Introduccion'>5- Seguridad de la Información</p>
                <p className='modal-body'>
                  Implementamos medidas de seguridad robustas para proteger la información del usuario contra accesos
                  no autorizados, alteraciones, divulgaciones o destrucciones.
                </p>
                <p className='Introduccion'>6-  Derechos del Usuario</p>
                <p className='modal-body'>
                  Los usuarios tienen derecho a:
                  <ul>
                    <li>Acceder a su información personal.</li>
                    <li>Solicitar la corrección o eliminación de su información.</li>
                    <li>Oponerse al procesamiento de sus datos personales.</li>
                  </ul>
                </p>
                <p className='Introduccion'>7- Cookies y Tecnologías Similares</p>
                <p className='modal-body'>
                  Utilizamos cookies y tecnologías similares para mejorar la funcionalidad de la plataforma y
                  personalizar la experiencia del usuario. Los usuarios pueden configurar su navegador para
                  rechazar cookies, pero esto puede afectar algunas funcionalidades de la plataforma.
                </p>
                <p className='Introduccion'>8- Cambios en la Política de Privacidad</p>
                <p className='modal-body'>
                  Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento.
                  Los cambios se notificarán a los usuarios y el uso continuado de la plataforma constituirá la
                  aceptación de la nueva política.
                </p>
                <p className='Introduccion'>9- Contacto</p>
                <p className='modal-body'>
                  Para cualquier pregunta o inquietud sobre estos términos y condiciones, puede contactarnos
                  en <a href='mailto:tccmuseos@gmail.com'>tccmuseos@gmail.com</a>
                </p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}

export default Panda;
