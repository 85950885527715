import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo1 from '../../components/Imagenes/Recurso.png';
import { Button, Modal, Alert } from 'react-bootstrap';
import '../CSS/LugaresE.css';
import { db } from '../../DB/firebase'; // Importa la configuración de Firebase
import { collection, addDoc, updateDoc, doc, deleteDoc, getDocs } from 'firebase/firestore'; // Importa funciones de Firestore
import agregar from '../../components/Imagenes/agregar.png';
import aprobe from '../../components/Imagenes/seleccionar.png';
import editar from '../../components/Imagenes/editar.png';
import eliminar from '../../components/Imagenes/eliminar.png';

function LugEmblematicos() {
    const [lugares, setLugares] = useState([]); // Estado para almacenar los lugares 
    const [showModal, setShowModal] = useState(false); // Estado para controlar la vista del modal
    const [isEditing, setIsEditing] = useState(false); // Estado para saber si se está editando un lugar
    const [currentLugar, setCurrentLugar] = useState(null); // Estado para almacenar el lugar actual que se está editando y se hagan los cambios
    const [imagen, setImagen] = useState(null); // Estado para la almacenar la imagen del lugar
    const [descripcion, setDescripcion] = useState(''); // Estado para la descripción del lugar
    const [ubicacion, setUbicacion] = useState(''); // Estado para la ubicación del lugar
    const [alert, setAlert] = useState({ show: false, message: '' }); // Estado para mostrar alertas

    // useEffect para obtener y cargar los lugares emblemáticos desde Firestore para mostrar los datos
    useEffect(() => {
        const fetchLugares = async () => {
            const querySnapshot = await getDocs(collection(db, 'lugaresEmblematicos'));
            const lugaresData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setLugares(lugaresData);
        };

        fetchLugares(); // Llama a la función para obtener los datos
    }, []);

    // Maneja el cambio de imagen
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagen(reader.result);
        };
        reader.readAsDataURL(file);
    };

    // Abre el modal para agregar un nuevo lugar
    const handleShowAddModal = () => {
        setImagen(null);
        setDescripcion('');
        setUbicacion('');
        setIsEditing(false);
        setShowModal(true);
    };

    // Abre el modal para editar un lugar existente
    const handleShowEditModal = (lugar) => {
        setCurrentLugar(lugar);
        setImagen(lugar.imagen);
        setDescripcion(lugar.descripcion);
        setUbicacion(lugar.ubicacion);
        setIsEditing(true);
        setShowModal(true);
    };

    // Cierra el modal y limpia los estados
    const handleClose = () => {
        setShowModal(false);
        setImagen(null);
        setDescripcion('');
        setUbicacion('');
        setIsEditing(false);
        setCurrentLugar(null);
    };

    // Agrega un nuevo lugar
    const handleAgregarLugar = async () => {
        const nuevoLugar = { imagen, descripcion, ubicacion, publicado: false };

        const docRef = await addDoc(collection(db, 'lugaresEmblematicos'), nuevoLugar);

        setLugares([...lugares, { id: docRef.id, ...nuevoLugar }]);
        handleClose();
        showAlert('Lugar agregado correctamente');
    };

    // Guarda los cambios de la edición
    const handleSaveEdit = async () => {
        const lugarRef = doc(db, 'lugaresEmblematicos', currentLugar.id);

        await updateDoc(lugarRef, {
            imagen,
            descripcion,
            ubicacion
        });

        const updatedLugares = lugares.map(l => {
            if (l.id === currentLugar.id) {
                return { ...l, imagen, descripcion, ubicacion };
            }
            return l;
        });

        setLugares(updatedLugares);
        handleClose();
        showAlert('Lugar editado correctamente');
    };

    // Elimina un lugar
    const handleDelete = async (id) => {
        const lugarRef = doc(db, 'lugaresEmblematicos', id);
        await deleteDoc(lugarRef);

        const updatedLugares = lugares.filter(l => l.id !== id);
        setLugares(updatedLugares);
        showAlert('Lugar eliminado correctamente');
    };

    // Sube un lugar (cambia su estado a publicado de esta manera se puede ver en la interfaz del usuario)
    const handleSubirLugar = async (id) => {
        const lugarRef = doc(db, 'lugaresEmblematicos', id);

        await updateDoc(lugarRef, {
            publicado: true
        });

        const updatedLugares = lugares.map(l => {
            if (l.id === id) {
                return { ...l, publicado: true };
            }
            return l;
        });

        setLugares(updatedLugares);
        showAlert('Lugar subido correctamente');
    };

    // Muestra una alerta
    const showAlert = (message) => {
        setAlert({ show: true, message });
        setTimeout(() => setAlert({ show: false, message: '' }), 3000);
    };

    return (
        <div>
            {alert.show && <Alert variant="success" className='alerta'>{alert.message}</Alert>}
            <nav className="navbar">
                <div className="container-fluid">
                    <button className="navbar-brand btn btn2">
                        <Link to="/InicioAdmin"><img src={logo1} alt="Bootstrap" width="40" height="35" /></Link>
                    </button>
                    <div className='d-flex'>
                        <Link to="/InicioAdmin" className='letra' style={{ textDecoration: 'none' }}>
                            <button className="btn btn-agenda" type='button'>
                                Inicio
                            </button>
                        </Link>
                    </div>
                </div>
            </nav>
            <body>
                <h1 className='bienvenido'>Agregar Lugar Emblemático
                    <Button variant="warning" className='BotonL btnEven' onClick={handleShowAddModal}>
                        <img src={agregar} alt="agregar" width="20" height="20" /> Nuevo Lugar
                    </Button>
                </h1>
                <Modal show={showModal} onHide={handleClose}>
                    <Modal.Header closeButton className='n'>
                        <Modal.Title>{isEditing ? 'Editar Lugar' : 'Agregar Nuevo Lugar'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3">
                            <h2>Imagen</h2>
                            <input type="file" onChange={handleImageChange} className="form-control" />
                        </div>
                        {imagen && (
                            <div className="mb-3">
                                <img src={imagen} alt="Selected" className="img-fluid" />
                            </div>
                        )}
                        <div className="mb-3">
                            <textarea
                                placeholder="Descripción"
                                value={descripcion}
                                onChange={(e) => setDescripcion(e.target.value)}
                                className="form-control"
                                rows="5"
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                placeholder="Ubicación"
                                value={ubicacion}
                                onChange={(e) => setUbicacion(e.target.value)}
                                className="form-control"
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" className='color2' onClick={handleClose}>Cancelar</Button>
                        <Button variant="warning" className='btnEven' onClick={isEditing ? handleSaveEdit : handleAgregarLugar}>
                            {isEditing ? 'Guardar Cambios' : 'Agregar Lugar'}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <div className="cuadros-container1">
                    {lugares.map((lugar, index) => (
                        <div key={index} className='CuadroLugar'>
                            <img src={lugar.imagen || 'default-image.jpg'} className="fotoXD" alt="Imagen del lugar" />
                            <p className='descripcionje'>{lugar.descripcion || 'Descripción predeterminada'}</p>
                            <p>{lugar.ubicacion || 'Ubicación predeterminada'}</p>
                            <div className="button-container">
                                <Button variant="warning" className='btnEven' onClick={() => handleShowEditModal(lugar)}><img src={editar} alt="editar"  width="20" height="20"/> Editar</Button>
                                <Button variant="warning" className='color2' onClick={() => handleDelete(lugar.id)}><img src={eliminar} alt="eliminar" width="20" height="20" /> Eliminar</Button>
                                <Button variant="warning" className='btnEven' onClick={() => handleSubirLugar(lugar.id)}><img src={aprobe} alt="subir" width="20" height="20" /> Subir Lugar</Button>
                            </div>
                        </div>
                    ))}
                </div>
            </body>
        </div>
    );
}

export default LugEmblematicos;
