import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo1 from '../../components/Imagenes/Recurso.png';
import '../CSS/GestionAyD.css';
import { Button, Modal } from 'react-bootstrap';
import { db } from '../../DB/firebase';
import { collection, addDoc, updateDoc, deleteDoc, getDocs, doc } from "firebase/firestore";
import agregar from '../../components/Imagenes/agregar.png';
import aprobe from '../../components/Imagenes/seleccionar.png';
import editar from '../../components/Imagenes/editar.png';
import eliminar from '../../components/Imagenes/eliminar.png';

function AntesDespues() {
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [image, setImage] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('antes');
    const [items, setItems] = useState([]);
    const [editImage, setEditImage] = useState(null);
    const [editTitle, setEditTitle] = useState('');
    const [editDescription, setEditDescription] = useState('');
    const [editCategory, setEditCategory] = useState('');
    const [editId, setEditId] = useState(null);

    useEffect(() => {
        const fetchItems = async () => {
            const querySnapshot = await getDocs(collection(db, "antesDespues"));
            const itemsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            // Ordena los elementos por id o cualquier otro criterio que desees
            itemsData.sort((a, b) => a.id.localeCompare(b.id));
            setItems(itemsData);
        };

        fetchItems();
    }, []);

    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    // Función para cambiar la imagen al seleccionar en el modal de agregar nuevo
    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const base64Image = await toBase64(file);
            setImage(base64Image);
        }
    };

    // Función para cambiar la imagen al seleccionar en el modal de editar
    const handleEditImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const base64Image = await toBase64(file);
            setEditImage(base64Image);
        }
    };

    // Función para aprobar y agregar nuevo elemento
    const handleApprove = async () => {
        const newItem = { image, title, description, category, approved: false };

        try {
            const docRef = await addDoc(collection(db, "antesDespues"), newItem);
            newItem.id = docRef.id;
            setItems([...items, newItem].sort((a, b) => a.id.localeCompare(b.id)));
            console.log("Documento añadido con éxito!");
            alert('Elemento añadido correctamente');
        } catch (e) {
            console.error("Error al añadir documento: ", e);
        }
        clearModal();
    };

    // Función para editar elemento
    const handleEdit = (index) => {
        const item = items.find(item => item.id === index);
        setEditImage(item.image);
        setEditTitle(item.title);
        setEditDescription(item.description);
        setEditCategory(item.category);
        setEditId(item.id);
        setShowEditModal(true);
    };

    // Función para guardar edición de elemento
    const handleSaveEdit = async () => {
        const updatedItems = items.map(item => {
            if (item.id === editId) {
                return { ...item, image: editImage, title: editTitle, description: editDescription, category: editCategory };
            }
            return item;
        });
        setItems(updatedItems.sort((a, b) => a.id.localeCompare(b.id)));

        const itemDoc = doc(db, "antesDespues", editId);
        try {
            await updateDoc(itemDoc, {
                image: editImage,
                title: editTitle,
                description: editDescription,
                category: editCategory
            });
            console.log("Documento actualizado con éxito!");
        } catch (e) {
            console.error("Error al actualizar documento: ", e);
        }

        clearEditModal();
    };

    // Función para eliminar elemento
    const handleDelete = async (index) => {
        const itemToDelete = items.find(item => item.id === index);
        const updatedItems = items.filter(item => item.id !== index);
        setItems(updatedItems.sort((a, b) => a.id.localeCompare(b.id)));

        const itemDoc = doc(db, "antesDespues", itemToDelete.id);
        try {
            await deleteDoc(itemDoc);
            console.log("Documento eliminado con éxito!");
        } catch (e) {
            console.error("Error al eliminar documento: ", e);
        }

        clearEditModal();
    };

    // Función para manejar la subida de elemento
    const handleUpload = async (id) => {
        const itemDoc = doc(db, "antesDespues", id);
        try {
            await updateDoc(itemDoc, { approved: true });
            console.log("Documento actualizado con éxito!");

            const updatedItems = items.map(item => {
                if (item.id === id) {
                    return { ...item, approved: true };
                }
                return item;
            });
            setItems(updatedItems.sort((a, b) => a.id.localeCompare(b.id)));
            alert('Subido correctamente');
        } catch (e) {
            console.error("Error al actualizar documento: ", e);
        }
    };

    // Función para limpiar el modal de agregar nuevo
    const clearModal = () => {
        setImage(null);
        setTitle('');
        setDescription('');
        setCategory('antes');
        setShowModal(false);
    };

    // Función para limpiar el modal de editar
    const clearEditModal = () => {
        setEditImage(null);
        setEditTitle('');
        setEditDescription('');
        setEditCategory('');
        setShowEditModal(false);
    };

    return (
        <div>
            <nav className="navbar">
                <div className="container-fluid">
                    <button className="navbar-brand btn btn2">
                        <Link to="/InicioAdmin"><img src={logo1} alt="Bootstrap" width="40" height="35" /></Link>
                    </button>
                    <div className='d-flex'>
                        <Link to="/InicioAdmin" className='letra' style={{ textDecoration: 'none' }}>
                            <button className="btn btn-agenda" type='button'>
                                Inicio
                            </button>
                        </Link>
                    </div>
                </div>
            </nav>
            <body>
                <h1 className='bienvenido'>Tulancingo: Antes y Después
                    <Button variant="warning" className='deleted btnAD' onClick={() => setShowModal(true)}>
                        <img src={agregar} alt="agregar" width="20" height="20" />Agregar Nuevo
                    </Button>
                </h1>
                {/* Modal para agregar nueva imagen */}
                <Modal show={showModal} onHide={clearModal}>
                    <Modal.Header closeButton className='n'>
                        <Modal.Title>Agregar Nuevo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3">
                            <label className="form-label">Subir imagen</label>
                            <input type="file" onChange={handleImageChange} className="form-control" />
                        </div>
                        {image && (
                            <div className="mb-3">
                                <img src={image} alt="Selected" className="img-fluid" />
                            </div>
                        )}
                        <div className="mb-3">
                            <label className="form-label">Título</label>
                            <input
                                type="text"
                                placeholder="Título"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Descripción</label>
                            <textarea
                                placeholder="Descripción"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Categoría</label>
                            <select
                                className="form-select"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                            >
                                <option value="antes">Antes</option>
                                <option value="despues">Después</option>
                            </select>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" className='color2' onClick={clearModal}>Cancelar</Button>
                        <Button variant="warning" className='btnAD' onClick={handleApprove}>Aprobar</Button>
                    </Modal.Footer>
                </Modal>
                {/* Modal para editar */}
                <Modal show={showEditModal} onHide={clearEditModal}>
                    <Modal.Header closeButton className='n'>
                        <Modal.Title>Editar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3">
                            <input type="file" onChange={handleEditImageChange} className="form-control" />
                        </div>
                        {editImage && (
                            <div className="mb-3">
                                <img src={editImage} alt="Selected" className="img-fluid" />
                            </div>
                        )}
                        <div className="mb-3">
                            <input
                                type="text"
                                placeholder="Título"
                                value={editTitle}
                                onChange={(e) => setEditTitle(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="mb-3">
                            <textarea
                                placeholder="Descripción"
                                value={editDescription}
                                onChange={(e) => setEditDescription(e.target.value)}
                                className="form-control"
                                rows="5"
                            />
                        </div>
                        <div className="mb-3">
                            <select
                                className="form-select"
                                value={editCategory}
                                onChange={(e) => setEditCategory(e.target.value)}
                            >
                                <option value="antes">Antes</option>
                                <option value="despues">Después</option>
                            </select>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" className='color2' onClick={clearEditModal}>Cancelar</Button>
                        <Button variant="warning" className='btnAD' onClick={handleSaveEdit}>Guardar</Button>
                    </Modal.Footer>
                </Modal>


                <div className='cuadros-container1'>
                    {/* Sección Antes */}
                    <div className='cuadro1'>
                        {items.filter(item => item.category === 'antes').map((item, index) => (
                            <div key={item.id}>
                                <img src={item.image || 'default-image-url.jpg'} alt={item.title} className="fotoXD" />
                                <h5>{item.title}</h5>
                                <p>{item.description}</p>
                                <button onClick={() => handleEdit(item.id)} className='btn btn-warning editar btnAD '><img src={editar} alt="agregar" width="20" height="20" /> Editar</button>
                                <button onClick={() => handleDelete(item.id)} className='btn btn-danger editar btnEl'><img src={eliminar} alt="agregar" width="20" height="20" /> Eliminar</button>
                                <button onClick={() => handleUpload(item.id)} className='btn btn-warning editar btnAD'><img src={aprobe} alt="agregar" width="20" height="20" /> Subir</button>
                            </div>
                        ))}
                    </div>
                    {/* Sección Después */}
                    <div className='cuadro1'>
                        {items.filter(item => item.category === 'despues').map((item, index) => (
                            <div key={item.id}>
                                <img src={item.image || 'default-image-url.jpg'} alt={item.title} className="fotoXD" />
                                <h5>{item.title}</h5>
                                <p>{item.description}</p>
                                <button onClick={() => handleEdit(item.id)} className='btn btn-warning editar btnAD'><img src={editar} alt="agregar" width="20" height="20" />  Editar</button>
                                <button onClick={() => handleDelete(item.id)} className='btn btn-danger editar btnEl'><img src={eliminar} alt="agregar" width="20" height="20" /> Eliminar</button>
                                <button onClick={() => handleUpload(item.id)} className='btn btn-warning editar btnAD'><img src={aprobe} alt="agregar" width="20" height="20" /> Subir</button>

                            </div>
                        ))}
                    </div>
                </div>
            </body>
        </div>
    );
}

export default AntesDespues;
