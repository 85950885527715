import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Card, Modal, Form } from 'react-bootstrap';
import logo1 from '../../components/Imagenes/Recurso.png';
import agregar from '../../components/Imagenes/agregar.png';
import editar from '../../components/Imagenes/editar.png';
import eliminar from '../../components/Imagenes/eliminar.png';
import './../CSS/RecorridoVir.css';
import { db } from '../../DB/firebase';
import { collection, addDoc, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

 
function RecorridoV() {
    const [showModal, setShowModal] = useState(false);
    const [roomName, setRoomName] = useState('');
    const [roomDescription, setRoomDescription] = useState('');
    const [roomFiles, setRoomFiles] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [hoveredRoom, setHoveredRoom] = useState(null);

    const [showEditModal, setShowEditModal] = useState(false);
    const [editRoomId, setEditRoomId] = useState(null);
    const [editRoomName, setEditRoomName] = useState('');
    const [editRoomDescription, setEditRoomDescription] = useState('');
    const [editRoomFiles, setEditRoomFiles] = useState([]);

    const [showNarrativeModal, setShowNarrativeModal] = useState(false);
    const [narrativeName, setNarrativeName] = useState('');
    const [narrativeFiles, setNarrativeFiles] = useState([]);
    const [narratives, setNarratives] = useState([]);
    const [hoveredNarrative, setHoveredNarrative] = useState(null);

    const [showEditNarrativeModal, setShowEditNarrativeModal] = useState(false);
    const [editNarrativeId, setEditNarrativeId] = useState(null);
    const [editNarrativeName, setEditNarrativeName] = useState('');
    const [editNarrativeFiles, setEditNarrativeFiles] = useState([]);

    useEffect(() => {
        const fetchRooms = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'recorrido'));
                const roomsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setRooms(roomsData);
            } catch (error) {
                console.error("Error fetching rooms: ", error);
                setRooms([]);
            }
        };

        const fetchNarratives = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'narrativaV'));
                const narrativesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setNarratives(narrativesData);
            } catch (error) {
                console.error("Error fetching narratives: ", error);
                setNarratives([]);
            }
        };

        fetchRooms();
        fetchNarratives();
    }, []);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const handleShowNarrativeModal = () => setShowNarrativeModal(true);
    const handleCloseNarrativeModal = () => setShowNarrativeModal(false);
    const handleShowEditModal = () => setShowEditModal(true);
    const handleCloseEditModal = () => setShowEditModal(false);
    const handleShowEditNarrativeModal = () => setShowEditNarrativeModal(true);
    const handleCloseEditNarrativeModal = () => setShowEditNarrativeModal(false);


    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const filesDataURLs = await Promise.all(
                Array.from(roomFiles).map(file => convertFileToDataURL(file))
            );

            const newRoom = {
                nombre: roomName,
                descripcion: roomDescription,
                archivos: filesDataURLs,
            };

            const docRef = await addDoc(collection(db, 'recorrido'), newRoom);
            setRooms([...rooms, { id: docRef.id, ...newRoom }]);

            handleCloseModal();
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    const handleEditFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const updatedFilesDataURLs = await Promise.all(
                Array.from(editRoomFiles).map(file => convertFileToDataURL(file))
            );

            const updatedRoom = {
                nombre: editRoomName,
                descripcion: editRoomDescription,
                archivos: updatedFilesDataURLs,
            };

            await updateDoc(doc(db, 'recorrido', editRoomId), updatedRoom);
            setRooms(rooms.map(room => (room.id === editRoomId ? { id: editRoomId, ...updatedRoom } : room)));

            handleCloseEditModal();
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    };
    const handleDeleteRoom = async (roomId) => {
        try {
            await deleteDoc(doc(db, 'recorrido', roomId));
            setRooms(rooms.filter(room => room.id !== roomId));
        } catch (error) {
            console.error("Error deleting document: ", error);
        }
    };

    const handleEditClick = (room) => {
        setEditRoomId(room.id);
        setEditRoomName(room.nombre);
        setEditRoomDescription(room.descripcion);
        setEditRoomFiles([]);
        handleShowEditModal();
    };



    const storage = getStorage();

    const handleNarrativeFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const filePromises = Array.from(narrativeFiles).map(async (file) => {
                const storageRef = ref(storage, `narrativaV/${file.name}`);
                await uploadBytes(storageRef, file);
                return getDownloadURL(storageRef);
            });

            const fileUrls = await Promise.all(filePromises);

            const newNarrative = {
                nombre: narrativeName,
                archivos: fileUrls,
            };

            const docRef = await addDoc(collection(db, 'narrativaV'), newNarrative);
            setNarratives([...narratives, { id: docRef.id, ...newNarrative }]);

            handleCloseNarrativeModal();
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    const handleEditNarrativeFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const filePromises = Array.from(editNarrativeFiles).map(async (file) => {
                const storageRef = ref(storage, `narrativaV/${file.name}`);
                await uploadBytes(storageRef, file);
                return getDownloadURL(storageRef);
            });

            const fileUrls = await Promise.all(filePromises);

            const updatedNarrative = {
                nombre: editNarrativeName,
                archivos: fileUrls,
            };

            await updateDoc(doc(db, 'narrativaV', editNarrativeId), updatedNarrative);
            setNarratives(narratives.map(narrative => (narrative.id === editNarrativeId ? { id: editNarrativeId, ...updatedNarrative } : narrative)));

            handleCloseEditNarrativeModal();
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    };

    const handleDeleteNarrative = async (narrativeId) => {
        try {
            await deleteDoc(doc(db, 'narrativaV', narrativeId));
            setNarratives(narratives.filter(narrative => narrative.id !== narrativeId));
        } catch (error) {
            console.error("Error deleting document: ", error);
        }
    };
    const handleEditNarrativeClick = (narrative) => {
        setEditNarrativeId(narrative.id);
        setEditNarrativeName(narrative.nombre);
        setEditNarrativeFiles([]);
        handleShowEditNarrativeModal();
    };

    const handleFileChange = (e) => {
        setRoomFiles(e.target.files);
    };

    const handleEditFileChange = (e) => {
        setEditRoomFiles(e.target.files);
    };

    const handleNarrativeFileChange = (e) => {
        setNarrativeFiles(e.target.files);
    };

    const handleEditNarrativeFileChange = (e) => {
        setEditNarrativeFiles(e.target.files);
    };



    const convertFileToDataURL = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const handleDownload = (file, idx) => {
        const link = document.createElement('a');
        link.href = file;
        link.download = `recorrido-${idx}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };



    return (
        <div>
            <nav className="navbar">
                <div className="container-fluid">
                    <button className="navbar-brand btn btn2">
                        <Link to="/InicioAdmin"><img src={logo1} alt="Bootstrap" width="40" height="35" /></Link>
                    </button>
                    <div className='d-flex'>
                        <Link to="/InicioAdmin" className='letra' style={{ textDecoration: 'none' }}>
                            <button className="btn btn-agenda" type='button'>
                                Inicio
                            </button>
                        </Link>
                    </div>
                </div>
            </nav>
            <body>
                <h1 className='bienvenido'>Panel de Administración del Museo</h1>
                <div className='container-fluid'>
                    <Row className="mt-4">
                        <Col md={6} className="d-flex align-items-stretch">
                            <Card className="flex-grow-1 d-flex flex-column">
                                <Card.Body className="d-flex flex-column">
                                    <Card.Title>Tour Virtual Fotos</Card.Title>
                                    <div className="d-flex mb-4">
                                        <Button variant="warning" className='me-2 flex-grow-1 btnRecorrido' onClick={handleShowModal}>
                                            <img src={agregar} alt="agregarSala" className='agregarS' width="20" height="20" />  Crear Sala
                                        </Button>
                                    </div>
                                    <Card.Subtitle className="mb-2 text-muted">Fotos del Ferrocarril</Card.Subtitle>
                                    {rooms.map((room, index) => (
                                        <Card className="mb-3" key={room.id} onMouseEnter={() => setHoveredRoom(index)} onMouseLeave={() => setHoveredRoom(null)}>
                                            <Card.Body>
                                                <Card.Title className='cardTitle'>{room.nombre}</Card.Title>
                                                <Card.Text className='cardDescription'>{room.descripcion}</Card.Text>
                                                {hoveredRoom === index && (
                                                    <Card.Text>
                                                        {room.archivos.map((file, idx) => (
                                                            <div key={idx}>
                                                                <img
                                                                    src={file}
                                                                    alt={`file-${idx}`}
                                                                    className='img-fluid img-small'
                                                                    onClick={() => handleDownload(file, idx)}
                                                                />
                                                            </div>
                                                        ))}
                                                    </Card.Text>
                                                )}
                                                <Button className='btnRecorrido me-2' variant="secondary" onClick={() => handleEditClick(room)}>
                                                    <img src={editar} alt="agregarNarrativa" className='agregarS' width="20" height="20" /> Editar
                                                </Button>
                                                <Button className='color2' variant="danger" onClick={() => handleDeleteRoom(room.id)}>
                                                    <img src={eliminar} alt="agregarNarrativa" className='agregarS' width="20" height="20" /> Eliminar
                                                </Button>
                                            </Card.Body>
                                        </Card>
                                    ))}
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} className="d-flex align-items-stretch">
                            <Card className="flex-grow-1 d-flex flex-column">
                                <Card.Body className="d-flex flex-column">
                                    <Card.Title>Tour Virtual Sonido</Card.Title>
                                    <div className="d-flex mb-4">
                                        <Button variant="warning" className='me-2 flex-grow-1 btnRecorrido' onClick={handleShowNarrativeModal}>
                                            <img src={agregar} alt="agregarNarrativa" className='agregarS' width="20" height="20" /> Crear Narrativa
                                        </Button>
                                    </div>
                                    <Card.Subtitle className="mb-2 text-muted">Narrativas del Ferrocarril</Card.Subtitle>
                                    {narratives.map((narrative, index) => (
                                        <Card className="mb-3" key={narrative.id} onMouseEnter={() => setHoveredNarrative(index)} onMouseLeave={() => setHoveredNarrative(null)}>
                                            <Card.Body>
                                                <Card.Title className='cardTitle'>{narrative.nombre}</Card.Title>
                                                {hoveredNarrative === index && (
                                                    <Card.Text>
                                                        {narrative.archivos.map((file, idx) => (
                                                            <div key={idx}>
                                                                <audio controls src={file} className='audioControls'></audio>
                                                            </div>
                                                        ))}
                                                    </Card.Text>
                                                )}
                                                <Button className='btnRecorrido me-2' variant="secondary" onClick={() => handleEditNarrativeClick(narrative)}>
                                                    <img src={editar} alt="agregarNarrativa" className='agregarS' width="20" height="20" /> Editar
                                                </Button>
                                                <Button className='color2' variant="danger" onClick={() => handleDeleteNarrative(narrative.id)}>
                                                    <img src={eliminar} alt="agregarNarrativa" className='agregarS' width="20" height="20" /> Eliminar
                                                </Button>
                                            </Card.Body>
                                        </Card>
                                    ))}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </body>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton className='n'>
                    <Modal.Title>Crear Sala</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleFormSubmit}>
                        <Form.Group controlId="roomName">
                            <Form.Label>Nombre de la Sala</Form.Label>
                            <Form.Control type="text" value={roomName} onChange={(e) => setRoomName(e.target.value)} required />
                        </Form.Group>
                        <Form.Group controlId="roomDescription">
                            <Form.Label>Descripción de la Sala</Form.Label>
                            <Form.Control as="textarea" value={roomDescription} onChange={(e) => setRoomDescription(e.target.value)} required />
                        </Form.Group>
                        <Form.Group controlId="roomFiles">
                            <Form.Label>Archivos</Form.Label>
                            <Form.Control type="file" multiple onChange={handleFileChange} required />
                        </Form.Group>
                        <Button variant="warning" className='btnRecorrido' type="submit">Crear Sala </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showEditModal} onHide={handleCloseEditModal}>
                <Modal.Header closeButton className='n'>
                    <Modal.Title>Editar Sala</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleEditFormSubmit}>
                        <Form.Group controlId="editRoomName">
                            <Form.Label>Nombre de la Sala</Form.Label>
                            <Form.Control type="text" value={editRoomName} onChange={(e) => setEditRoomName(e.target.value)} required />
                        </Form.Group>
                        <Form.Group controlId="editRoomDescription">
                            <Form.Label>Descripción de la Sala</Form.Label>
                            <Form.Control as="textarea" value={editRoomDescription} onChange={(e) => setEditRoomDescription(e.target.value)} required />
                        </Form.Group>
                        <Form.Group controlId="editRoomFiles">
                            <Form.Label>Archivos</Form.Label>
                            <Form.Control type="file" multiple onChange={handleEditFileChange} />
                        </Form.Group>
                        <Button variant="warning" className='btnRecorrido' type="submit">Guardar Cambios</Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showNarrativeModal} onHide={handleCloseNarrativeModal}>
                <Modal.Header closeButton className='n'>
                    <Modal.Title>Crear Narrativa</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleNarrativeFormSubmit}>
                        <Form.Group controlId="narrativeName">
                            <Form.Label>Nombre de la Narrativa</Form.Label>
                            <Form.Control type="text" value={narrativeName} onChange={(e) => setNarrativeName(e.target.value)} required />
                        </Form.Group>
                        <Form.Group controlId="narrativeFiles">
                            <Form.Label>Archivos</Form.Label>
                            <Form.Control type="file" multiple onChange={handleNarrativeFileChange} accept=".aac,audio/*" required />
                        </Form.Group>
                        <Button variant="warning" className='btnRecorrido' type="submit">Crear Narrativa</Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showEditNarrativeModal} onHide={handleCloseEditNarrativeModal}>
                <Modal.Header closeButton className='n'>
                    <Modal.Title>Editar Narrativa</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleEditNarrativeFormSubmit}>
                        <Form.Group controlId="editNarrativeName">
                            <Form.Label>Nombre de la Narrativa</Form.Label>
                            <Form.Control type="text" value={editNarrativeName} onChange={(e) => setEditNarrativeName(e.target.value)} required />
                        </Form.Group>
                        <Form.Group controlId="editNarrativeFiles">
                            <Form.Label>Archivos</Form.Label>
                            <Form.Control type="file" multiple onChange={handleEditNarrativeFileChange} accept=".aac,audio/*" />
                        </Form.Group>
                        <Button variant="warning" className='btnRecorrido' type="submit">Guardar Cambios</Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default RecorridoV;